import React from 'react';
import { useTheme } from 'styled-components';
import { OSKThemeType, Text } from 'oskcomponents';
import CSS from 'csstype';

type HelloProps = {
    style?: CSS.Properties;
    className?: string;
    username?: string;
};

const Hello = ({ username, style, className }: HelloProps) => {
    const theme = useTheme() as OSKThemeType;
    return username ? (
        <Text
            as="div"
            className={className}
            style={{
                paddingRight: `${theme.navItemPadding}px`,
                textAlign: 'center',
                verticalAlign: 'center',
                ...style,
            }}
        >
            Hello, {username}
        </Text>
    ) : (
        <React.Fragment />
    );
};

export { Hello };
