import { LatLngExpression } from 'leaflet';

export type OSKPosition = {
    coords: LatLngExpression;
    zoom: number;
};

export const DEFAULT_OSK_POSITION: OSKPosition = {
    coords: [37.779, -122.47], // San Francisco
    zoom: 13.0, // A reasonable zoom
};

/**
 * This method will decode an OSKPosition string into an array
 * of [lon,lat]
 *
 * @param position An OSKPosition string
 * @returns An array of coordinates
 */
export const decodeOSKPosition = (position?: string): OSKPosition => {
    if (position === undefined || position === null || !position.startsWith('@') || !position.includes(',')) {
        // Return defaults if there is no position data
        return DEFAULT_OSK_POSITION;
    }

    const paths = position.substring(1).split(',');
    return {
        coords: [parseFloat(paths[0]), parseFloat(paths[1])],
        zoom: paths.length > 2 ? parseFloat(paths[2]) : 13.0,
    };
};

/**
 * This method takes an OSKPosition object and returns a string
 * which can later be decoded.
 *
 * @param position An OSKPosition object
 * @returns a string
 */
export const encodeOSKPosition = (position: OSKPosition): string => {
    return `@${position.coords.toString()},${position.zoom}`;
};
