import { Box, Button, Form, Heading, OSKThemeType, PageScaffold, Text } from 'oskcomponents';
import { AdminAPI, OSKView, User } from 'oskcore';
import { useProfileMetadata } from 'oskcore/src/hooks/useProfileMetadata';
import React from 'react';
import { useTheme } from 'styled-components';
import { ProfileForm, ProfileSchema } from '~/organisms/forms/ProfileForm';

export const ProfileSetupView = () => {
    const theme = useTheme() as OSKThemeType;
    const [profile, setProfile] = useProfileMetadata();
    const handleSubmit = (data: any) => {
        if (profile?.id) {
            AdminAPI.updateProfile({ userRequest: data as User });
            setProfile({
                ...profile,
                ...data,
            });
        }
    };

    return (
        <OSKView
            headerStyle={{
                boxShadow: theme.boxShadow,
            }}
            nogradient
        >
            <PageScaffold>
                <Box style={{ justifyContent: 'center' }} grow>
                    <Box p={10} w={500} style={{ alignSelf: 'center' }} col>
                        <Box style={{ paddingBottom: '40px', textAlign: 'center' }} col>
                            <Heading>Welcome to Sigma Data!</Heading>
                            <Text>Please fill out your profile to continue.</Text>
                        </Box>
                    </Box>
                </Box>
                <Box p={10} col>
                    <Form schema={ProfileSchema} onSubmit={handleSubmit}>
                        <ProfileForm editMode={true} data={profile} />
                        <Box style={{ justifyContent: 'flex-end' }}>
                            <Button label="Save Changes" submit variant="neutral" inverted />
                        </Box>
                    </Form>
                </Box>
            </PageScaffold>
        </OSKView>
    );
};
