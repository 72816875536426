import React, { useCallback, useContext, useRef, useState } from 'react';
import { reduce } from 'lodash';
import CSS from 'csstype';
import * as yup from 'yup';

type FormProps = {
    /** ClassName which is passed to the form container */
    className?: string;
    /** A yup schema which will be applied to validate inputs onSubmit. */
    schema?: yup.TypeOf<any>;
    /** Style which is passed to the form container */
    style?: CSS.Properties;
    /** Children which are rendered within the form */
    children?: React.ReactNode;
    /** Method which is invoked when the form is submitted. It receives
     *  an object containing all the input elements and their respective values. */
    onSubmit?: (obj: Record<string, any>) => void;
} & JSX.IntrinsicElements['form'];

/***
 * The ErrorContext object is defined as an object where each key
 * is the input element name which failed validation, and the
 * value is the associated error message.
 */
type ErrorContextType = {
    [key: string]: string;
};

/**
 * Takes a snake-case word and splits it up, capitalizing
 * each word in series.
 *
 * @param paramName A snake-case parameter name to titleize
 * @returns The titleized parameter name
 */
const titleize = (paramName: string) => {
    return paramName
        .split('_')
        .map((word) => (word.charAt(0) || '').toUpperCase() + word.substring(1))
        .join(' ');
};

export const ErrorContext = React.createContext<ErrorContextType>({});
export const FormContext = React.createContext<React.MutableRefObject<HTMLFormElement | null> | null>(null);

/** This method returns a ref to the closest form. */
export function useForm(): React.MutableRefObject<HTMLFormElement> | null {
    const form = useContext(FormContext);
    if (form && form.current) {
        return form as React.MutableRefObject<HTMLFormElement>;
    } else {
        return null;
    }
}

function findAllElements(formEl: HTMLFormElement) {
    return formEl.querySelectorAll<HTMLInputElement>('input');
}

export function getFormValues(formEl: HTMLFormElement) {
    const inputElements = findAllElements(formEl);
    return reduce(
        inputElements,
        (acc: Record<string, any>, el: HTMLInputElement) => {
            if (el.name && el.value && !el.disabled) {
                acc[el.name] = el.value;
            }
            return acc;
        },
        {},
    );
}

const Form = ({ children, onSubmit, schema, ...props }: FormProps) => {
    const ref = useRef<any>(null);
    const [errors, setErrors] = useState<ErrorContextType>({});
    const handleSubmit = useCallback(
        (evt: React.FormEvent) => {
            evt.preventDefault();
            evt.stopPropagation();

            const formEl = evt.target as HTMLFormElement;
            const result = getFormValues(formEl);

            // Attempt to validate the schema, if one was provided.
            if (schema) {
                const errorDetails: ErrorContextType = {};
                try {
                    schema.validateSync(result, {
                        strict: false,
                        abortEarly: false,
                    });
                    // Submit the form if there were no errors
                    onSubmit && onSubmit(result);
                } catch (validationError: any) {
                    for (const error of validationError.inner) {
                        errorDetails[error.path] = error.message.replace(error.path, titleize(error.path));
                    }
                }
                setErrors(errorDetails);
            } else {
                // Submit the form if there is no schema to validate first.
                onSubmit && onSubmit(result);
            }
        },
        [onSubmit, schema],
    );

    return (
        <form ref={ref} role="form" onSubmit={handleSubmit} {...props}>
            <FormContext.Provider value={ref}>
                <ErrorContext.Provider value={errors}>{children}</ErrorContext.Provider>
            </FormContext.Provider>
        </form>
    );
};

export type { FormProps };
export { Form };
