import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from 'oskcomponents';

export type ViewContainerContextType = {
    /** The name of the view which is initially for rendering */
    activeView?: string;
    /** The method to invoke which causes the view to change */
    changeView: (view: string) => void;
};

export const ViewContainerContext = React.createContext<ViewContainerContextType>({
    activeView: undefined,
    changeView: (_: string) => {},
});

function computeDefaultView(defaultView: string, shouldPersist: boolean | undefined, storageKey: string): string {
    if (shouldPersist) {
        return localStorage.getItem(storageKey) ?? defaultView;
    } else {
        return defaultView;
    }
}

export type ViewContainerProps = {
    /** Subsequent children to render */
    children: React.ReactNode;
    /** The default view to use when loading this component */
    defaultView?: string;
    /** A unique identifier for this view component, used for local storage purposes */
    viewKey: string;
    /** If true, the view will be stored in localStorage and persisted on page refresh */
    persist?: boolean;
} & Omit<BoxProps, 'ref'>;

/**
 * A container which hosts context information about an active view.
 * To use this component, you first must define one or more views
 * by composing <ViewContent /> elements. Once you have one or more
 * ViewContents, you can toggle between them by composing <ViewToggle />
 * buttons.
 *
 * ToggleButton and ViewContent each take a prop: viewKey.
 * When the ToggleButton containing a particular viewKey matches a ViewContent with
 * the same viewKey - that ViewContent will be rendered accordingly.
 *
 * A full example can be found in ~/organisms/cart/index.tsx
 */
export const ViewContainer = ({ children, defaultView, viewKey, persist, ...props }: ViewContainerProps) => {
    const STORAGE_KEY = `DEFAULT_VALUE_VIEW_CONTAINER_${viewKey}`;
    const [activeView, setActiveView] = useState(computeDefaultView(defaultView ?? '', persist, STORAGE_KEY));

    useEffect(() => {
        if (persist) {
            localStorage.setItem(STORAGE_KEY, activeView ?? defaultView ?? '');
        }
    }, [defaultView, STORAGE_KEY, persist, activeView]);

    return (
        <Box {...props}>
            <ViewContainerContext.Provider
                value={{
                    activeView: activeView,
                    changeView: setActiveView,
                }}
            >
                {children}
            </ViewContainerContext.Provider>
        </Box>
    );
};
