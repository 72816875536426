import React from 'react';
import { Box } from 'oskcomponents';
import styled from 'styled-components';

/// The status of this step
export type StepStatus = 'Current' | 'Done' | 'Pending';
export type WizardStepProps = {
    /// The css class to apply to this step
    className?: string;
    /// The label to display for this step
    label?: string;
    /// The status of this step
    status?: StepStatus;
    /// The numeric step number
    stepNumber?: number;
};

const BaseWizardStep = styled(({ className, label, status, stepNumber }: WizardStepProps) => {
    return (
        <Box className={className} col>
            <Box>
                <Box className="stepNumber">{stepNumber}</Box>
                <Box className="stepDetails" col>
                    <div className="status">{status}</div>
                    <div className="label">{label}</div>
                </Box>
            </Box>
            <Box className="active-line" />
        </Box>
    );
})`
    & {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 15px;
    }

    &,
    .stepNumber {
        font-size: 1.15rem;
        font-weight: 700;
    }

    .stepDetails {
        align-self: center;
        padding-left: 10px;
    }
    .stepNumber {
        font-weight: bold;
        border-radius: 50%;
        height: 1px;
        width: 1px;
        padding: 24px;
        justify-content: center;
        align-items: center;
    }
    .status {
        font-size: 0.8rem;
    }
`;

const DoneStep = styled(BaseWizardStep)`
    .stepNumber {
        background-color: ${(props: any) => props.theme.colors.green100};
        color: ${(props: any) => props.theme.colors.green900};
    }
    .status {
        color: ${(props: any) => props.theme.colors.green100};
    }
`;

const CurrentStep = styled(BaseWizardStep)`
    .active-line {
        border-radius: 30px;
        background-color: ${(props: any) => props.theme.colors.orange900};
        width: 100%;
        height: 4px;
        margin-top: 10px;
    }

    .stepNumber {
        background-color: ${(props: any) => props.theme.colors.orange100};
        color: ${(props: any) => props.theme.colors.orange900};
        filter: drop-shadow(0px 0px 6px ${(props: any) => props.theme.colors.orange900});
    }
    .status {
        color: ${(props: any) => props.theme.colors.orange100};
    }
`;

const PendingStep = styled(BaseWizardStep)`
    .stepNumber {
        background-color: ${(props: any) => props.theme.colors.primary.bg};
        color: ${(props: any) => props.theme.colors.black800};
    }
`;

export const WizardStep = (props: WizardStepProps) => {
    switch (props.status) {
        case 'Current': {
            return <CurrentStep {...props} />;
        }

        case 'Done': {
            return <DoneStep {...props} />;
        }

        case 'Pending': {
            return <PendingStep {...props} />;
        }
    }

    return <React.Fragment />;
};
