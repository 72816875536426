import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, BoxProps, OSKThemeType, Spinner, Text } from 'oskcomponents';
import { FaDownload, FaTimes } from 'react-icons/fa';
import { format } from 'date-fns';

export type DownloadRequestItemProps = {
    uri: string;
    requestedItems: Array<number>;
    timeReady?: Date;
    timeRequested: Date;
    expired: boolean;
    isDownloading: boolean;
} & Omit<BoxProps, 'ref'>;

export const DownloadRequestItem = styled(
    ({ uri, requestedItems, timeReady, timeRequested, expired, isDownloading, ...props }: DownloadRequestItemProps) => {
        const theme = useTheme() as OSKThemeType;
        const singular: boolean = requestedItems.length === 1;
        return (
            <Box
                data-downloading={isDownloading}
                p={14}
                style={{ alignItems: 'center' }}
                data-expired={expired}
                {...props}
            >
                {requestedItems.length > 0 ? (
                    <React.Fragment>
                        {isDownloading ? (
                            <Spinner variant="Box" style={{ paddingRight: '14px' }} />
                        ) : (
                            <FaDownload style={{ paddingRight: '14px' }} />
                        )}
                        <Box className="uri" col>
                            {format(timeRequested, 'p')}
                            <a target="_blank" href={`${uri}`} rel="noreferrer">
                                {`${!isDownloading ? 'Download' : 'Downloading'} ${requestedItems.length} Footprint${singular ? '' : 's'
                                    }`}
                            </a>
                        </Box>
                        <Box grow />
                        {expired && <Text color={theme.colors.red}>Expired</Text>}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <FaTimes style={{ color: theme.colors.red, paddingRight: '14px' }} />
                        <Box className="uri" col>
                            {format(timeRequested, 'p')}
                            <p> Failed to download footprints</p>
                        </Box>
                        <Box grow />
                    </React.Fragment>
                )}
            </Box>
        );
    },
)`
    & a {
        color: inherit;
        text-decoration: none;
    }

    &:hover a {
        color: inherit;
        cursor: inherit;
    }

    &[data-expired='true'] .uri {
        text-decoration: line-through;
        color: ${(props: any) => props.theme.colors.disabled.fg};
    }

    &[data-downloading='true'] .uri {
        color: ${(props: any) => props.theme.colors.disabled.fg};
        pointer-events: none;
    }

    &:hover[data-expired='false'][data-downloading='false'] {
        background-color: ${(props: any) => props.theme.colors.hovered.invertedBg};
        color: ${(props: any) => props.theme.colors.hovered.invertedFg};
        cursor: pointer;
    }

    &:hover[data-downloading='true'] {
        color: inherit;
        cursor: not-allowed;
    }
`;
