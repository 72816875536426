import { Box, Table, TableColumn, TableProps, Text } from 'oskcomponents';
import { capitalize, noop } from 'oskcore';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Asset } from '~/redux/modules/monitor/app';
import { date_format } from '~/utils';

type MonitorAssetsTableProps = {
    /** The alert to populate the table with */
    data: Asset[];
} & TableProps;

const MonitorAssetsTable = ({ data, ...props }: MonitorAssetsTableProps) => {
    const navigate = useNavigate();
    const { programId, assetId } = useParams();

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Start',
                accessor: 'monitoring_start_date',
                Cell: ({ value }: any) => {
                    return <Text>{date_format(value) ?? '---'}</Text>;
                },
            },
            {
                Header: 'Frequency',
                accessor: 'monitoring_frequency',
                Cell: ({ value }: any) => {
                    return <Text>{capitalize(value) ?? '---'}</Text>;
                },
            },
        ],
        [],
    );

    return (
        <Fragment>
            {data.length > 0 ? (
                <Table
                    {...props}
                    onRowClick={(asset: Asset) => {
                        navigate(`/program/${programId}/monitor/${asset.id}/grid`);
                    }}
                    columns={columns}
                    data={data}
                    selectedIndex={data.findIndex((asset) => asset.id === parseInt(assetId || '-1'))}
                />
            ) : (
                <Box center="all">
                    <Text>No alerts found.</Text>
                </Box>
            )}
        </Fragment>
    );
};

export { MonitorAssetsTable };
export default connect(noop, noop)(MonitorAssetsTable);
