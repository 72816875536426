import React from 'react';
import { Box, BoxProps } from 'oskcomponents';
import styled from 'styled-components';

type ColorIndicatorProps = {
    /** The CSS-friendly string to use for color.
     * Literally: `background-color: {color};` */
    color: string;
} & Omit<BoxProps, 'ref'>;

const ColorIndicator = styled(({ className, color, ...props }: ColorIndicatorProps) => {
    return (
        <Box className={className} {...props}>
            <div className="indicator-circle" />
            <div className="indicator-circle glow" />
        </Box>
    );
})`
    & {
        align-items: center;
        justify-content: center;
    }

    .indicator-circle {
        border-radius: 50%;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: ${(props: any) => props.color};
    }

    .glow {
        filter: blur(6px);
    }
`;

export { ColorIndicator };
