import { OSKGeoJson } from '../../models';

/**
 * This method will take an array of coordinates and return a bounding box
 * which fully encompasses every point. Useful if you want to move
 * the map so it is centered over all the points in the list.
 *
 * @param shape An array of coordinate items
 * @returns A bounding box containing all the points
 */
export function getBounds(geoJsons: Array<OSKGeoJson>): Array<number[]> {
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = -Number.MAX_VALUE;
    let maxY = -Number.MAX_VALUE;

    for (const geoJson of geoJsons) {
        for (const feature of geoJson.toLeafletFeatureList()) {
            for (const coordinate of feature.coordinates) {
                const x = coordinate.lat;
                const y = coordinate.lng;

                if (minX > x) minX = x;
                if (x > maxX) maxX = x;
                if (minY > y) minY = y;
                if (y > maxY) maxY = y;
            }
        }
    }

    if (
        minX === Number.MAX_VALUE ||
        minY === Number.MAX_VALUE ||
        maxX === -Number.MAX_VALUE ||
        maxY === -Number.MAX_VALUE
    ) {
        console.error('Invalid bounds in geojson shape');
        return [];
    }

    return [
        [minX, minY],
        [maxX, maxY],
    ];
}
