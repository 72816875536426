import React, { useRef } from 'react';
import { NavItem, useProfileMetadata } from 'oskcore';
import { IconButton } from '~/atoms';
import { Box, OSKThemeType, Overlay, Divider, Button, useClickAway } from 'oskcomponents';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { NavItemEntry, showNavItem } from '~/redux/modules/data/nav';
import { UserProfileIcon } from '~/molecules';
import UserProgramsList from '~/organisms/UserProgramsList';
import { LogoutButton } from 'oskcore';
import { GlobalZIndex } from '~/constants';

export type UserProfileProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    onNavigate?: () => void;
};

export const UserProfile = ({ show, setShow, onNavigate }: UserProfileProps) => {
    const ref = useRef(null);
    const [profile] = useProfileMetadata();
    const theme = useTheme() as OSKThemeType;
    useClickAway(ref, () => setShow(false), show);

    return (
        <Box ref={ref} style={{ zIndex: GlobalZIndex.UserProfile }}>
            <NavItem>
                <UserProfileIcon onClick={() => setShow(!show)} />
                <Box style={{ position: 'absolute', top: '55px', right: 0 }}>
                    <Overlay
                        p={10}
                        show={show}
                        style={{ width: '250px', height: '450px', right: '0', overflow: 'hidden' }}
                        col
                    >
                        <Box
                            style={{
                                border: `1px solid ${theme.colors.primary.border}`,
                                overflow: 'hidden',
                                borderRadius: '10px',
                                backdropFilter: 'blur(10px)',
                                color: theme.colors.white,
                            }}
                            bg={theme.colors.gray2ab}
                            grow
                            col
                        >
                            <Box
                                grow
                                col
                                style={{ justifyContent: 'space-around', paddingTop: '20px', overflow: 'hidden' }}
                            >
                                <Link to="/settings/profile" onClick={onNavigate}>
                                    <IconButton
                                        iconSize={theme.largeIconSize}
                                        icon={FaUser}
                                        label="Settings"
                                        variant="action"
                                        fill="white"
                                        color="white"
                                        hoverable
                                    />
                                </Link>

                                <Divider m={14} bg={theme.colors.primary.accent} />

                                <UserProgramsList programs={profile.programs ?? []} />

                                <Box m={14} style={{ justifyContent: 'center' }}>
                                    <LogoutButton>
                                        <Button variant="primary" inverted>
                                            Sign Out
                                        </Button>
                                    </LogoutButton>
                                </Box>
                            </Box>
                        </Box>
                    </Overlay>
                </Box>
            </NavItem>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        show: state.data.nav.showUserProfile,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setShow: (show: boolean) => {
            dispatch(showNavItem(show ? NavItemEntry.UserProfile : NavItemEntry.None));
        },
        onNavigate: () => {
            // Close all nav items
            dispatch(showNavItem(NavItemEntry.None));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
