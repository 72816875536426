import React from 'react';

type LogoProps = {
    displayMode?: string;
};

const Logo = ({ displayMode }: LogoProps): React.ComponentPropsWithRef<'svg'> => {
    return (
        <svg width="110" height="42" viewBox="0 0 110 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_2873)">
                <path
                    d="M81.7676 1.02148H77.9268V41.6799H81.7676V1.02148Z"
                    fill={displayMode === 'gradient' ? 'url(#paint0_linear_11_2873)' : 'inherit'}
                />
                <path
                    d="M20.6596 42C9.27205 42 0 32.5939 0 21.0229C0 9.45193 9.27205 0.0305176 20.6596 0.0305176C32.0471 0.0305176 41.3191 9.45193 41.3191 21.0229C41.3191 32.5939 32.0471 42 20.6596 42ZM20.6596 3.93324C11.3875 3.93324 3.84085 11.6015 3.84085 21.0229C3.84085 30.4443 11.3875 38.1126 20.6596 38.1126C29.9316 38.1126 37.4783 30.4443 37.4783 21.0229C37.4783 11.6015 29.9316 3.93324 20.6596 3.93324Z"
                    fill={displayMode === 'gradient' ? 'url(#paint1_linear_11_2873)' : 'inherit'}
                />
                <path
                    d="M49.5561 26.5263C43.5998 20.474 43.5998 10.6105 49.5561 4.55824C55.5124 -1.49402 65.2196 -1.49402 71.1759 4.55824L68.4603 7.31759C63.9893 2.77458 56.7277 2.78982 52.2717 7.31759C47.8007 11.8606 47.8007 19.2392 52.2717 23.767L49.5561 26.5263Z"
                    fill={displayMode === 'gradient' ? 'url(#paint2_linear_11_2873)' : 'inherit'}
                />
                <path
                    d="M56.4129 41.817C52.467 41.817 48.5212 40.2925 45.5205 37.2435L48.2361 34.4842C52.7521 39.0729 60.0887 39.0729 64.5897 34.4842C69.1057 29.8954 69.1057 22.4406 64.5897 17.8671L67.3203 15.0925C73.3216 21.1905 73.3216 31.1303 67.3203 37.2435C64.3197 40.2925 60.3588 41.817 56.4129 41.817Z"
                    fill={displayMode === 'gradient' ? 'url(#paint3_linear_11_2873)' : 'inherit'}
                />
                <path
                    d="M103.642 1.02148H98.7213C98.7213 1.02148 91.3847 10.1532 88.6541 13.8273C85.7435 17.7453 85.1433 23.2487 88.6091 28.1881C91.1297 31.8012 99.2164 41.6799 99.2164 41.6799H104.138C104.138 41.6799 94.9855 30.2309 92.675 27.1057C89.7194 23.1115 89.2092 19.6814 92.675 15.0012C95.6306 11.007 103.642 1.02148 103.642 1.02148Z"
                    fill={displayMode === 'gradient' ? 'url(#paint4_linear_11_2873)' : 'inherit'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_11_2873"
                    x1="19.611"
                    y1="82.5711"
                    x2="124.207"
                    y2="-20.3663"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2213" stopColor="#4954C0" />
                    <stop offset="0.27" stopColor="#855A8A" />
                    <stop offset="0.3258" stopColor="#C56150" />
                    <stop offset="0.3522" stopColor="#DE643A" />
                    <stop offset="0.3938" stopColor="#E86531" />
                    <stop offset="0.4821" stopColor="#F96621" />
                    <stop offset="0.5488" stopColor="#FF671C" />
                    <stop offset="0.5877" stopColor="#FF7833" />
                    <stop offset="0.6693" stopColor="#FFA56E" />
                    <stop offset="0.7774" stopColor="#FFE6C5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11_2873"
                    x1="4.77659"
                    y1="37.1541"
                    x2="91.9697"
                    y2="-48.6566"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2213" stopColor="#4954C0" />
                    <stop offset="0.27" stopColor="#855A8A" />
                    <stop offset="0.3258" stopColor="#C56150" />
                    <stop offset="0.3522" stopColor="#DE643A" />
                    <stop offset="0.3938" stopColor="#E86531" />
                    <stop offset="0.4821" stopColor="#F96621" />
                    <stop offset="0.5488" stopColor="#FF671C" />
                    <stop offset="0.5877" stopColor="#FF7833" />
                    <stop offset="0.6693" stopColor="#FFA56E" />
                    <stop offset="0.7774" stopColor="#FFE6C5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11_2873"
                    x1="13.5372"
                    y1="52.1381"
                    x2="101.468"
                    y2="-34.3987"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2213" stopColor="#4954C0" />
                    <stop offset="0.27" stopColor="#855A8A" />
                    <stop offset="0.3258" stopColor="#C56150" />
                    <stop offset="0.3522" stopColor="#DE643A" />
                    <stop offset="0.3938" stopColor="#E86531" />
                    <stop offset="0.4821" stopColor="#F96621" />
                    <stop offset="0.5488" stopColor="#FF671C" />
                    <stop offset="0.5877" stopColor="#FF7833" />
                    <stop offset="0.6693" stopColor="#FFA56E" />
                    <stop offset="0.7774" stopColor="#FFE6C5" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_11_2873"
                    x1="26.8057"
                    y1="67.3243"
                    x2="114.737"
                    y2="-19.2129"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2213" stopColor="#4954C0" />
                    <stop offset="0.27" stopColor="#855A8A" />
                    <stop offset="0.3258" stopColor="#C56150" />
                    <stop offset="0.3522" stopColor="#DE643A" />
                    <stop offset="0.3938" stopColor="#E86531" />
                    <stop offset="0.4821" stopColor="#F96621" />
                    <stop offset="0.5488" stopColor="#FF671C" />
                    <stop offset="0.5877" stopColor="#FF7833" />
                    <stop offset="0.6693" stopColor="#FFA56E" />
                    <stop offset="0.7774" stopColor="#FFE6C5" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_11_2873"
                    x1="30.273"
                    y1="93.6678"
                    x2="129.773"
                    y2="-4.25457"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2213" stopColor="#4954C0" />
                    <stop offset="0.27" stopColor="#855A8A" />
                    <stop offset="0.3258" stopColor="#C56150" />
                    <stop offset="0.3522" stopColor="#DE643A" />
                    <stop offset="0.3938" stopColor="#E86531" />
                    <stop offset="0.4821" stopColor="#F96621" />
                    <stop offset="0.5488" stopColor="#FF671C" />
                    <stop offset="0.5877" stopColor="#FF7833" />
                    <stop offset="0.6693" stopColor="#FFA56E" />
                    <stop offset="0.7774" stopColor="#FFE6C5" />
                </linearGradient>
                <clipPath id="clip0_11_2873">
                    <rect width="110" height="42" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const OSKLogoSolid = Logo({});
const OSKLogoGradient = Logo({ displayMode: 'gradient' });

export { OSKLogoSolid, OSKLogoGradient };
