import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box, BoxProps, Text } from 'oskcomponents';
import { AbbreviationIcon } from '~/atoms';

export type IconLinkProps = {
    /** Icon label */
    label: string;
    /** Icon description */
    description: string;
    /** React router link address */
    linkTo: string;
} & Omit<BoxProps, 'ref'>;

/**
 *
 */
const IconLink = styled(({ className, description, label, linkTo, ...props }: IconLinkProps) => {
    return (
        <Link style={{ textDecoration: 'none' }} to={linkTo}>
            <Box className={className} {...props}>
                <AbbreviationIcon label={label} />
                <Text className="description">{description}</Text>
            </Box>
        </Link>
    );
})`
    cursor: pointer;
    padding: 10px 15px;
    color: ${(props: any) => props.theme.colors.white};

    & .description {
        display: flex;
        align-self: center;
        padding-left: 5px;
    }

    &:hover {
        background-color: ${(props: any) => props.theme.colors.gray0a};
    }
`;

export { IconLink };
