import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export type RedirectToProps = {
    href?: string;
};

/**
 * This component will redirect to a specific uri
 * once (and only once) based on the href passed in.
 *
 * If href is undefined, nothing will happen.
 */
export const RedirectTo = ({ href }: RedirectToProps) => {
    const navigate = useNavigate();
    const navigated = useRef(false);

    useEffect(() => {
        if (href && !navigated.current) {
            navigated.current = true;
            navigate(href);
        }
    }, [href, navigate]);

    return <></>;
};
