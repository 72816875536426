import React from 'react';
import CSS from 'csstype';
import { Box, BoxProps } from 'oskcomponents';

type ImageProps = {
    /** Image source to render */
    src?: string;
    /** Optional additional styling to pass to the image */
    style?: CSS.Properties;
    /** Children components to render on top of the image */
    children?: React.ReactNode;
    /** If true, the image will be resized to take up the whole
     * area (while cropping excess). If false, the image will
     * be squished into the area without cropping anything.
     */
    cover?: boolean;
} & Omit<BoxProps, 'ref'>;

/**
 * Image is a component which takes an src prop and can render
 * the underlying picture. It supports cover or contain by default
 * because the data is rendered into a div, rather than an image
 * tag.
 */
const Image = ({ children, cover, style, src, ...props }: ImageProps) => {
    return (
        <Box
            style={{
                background: `url(${src})`,
                backgroundSize: cover ? 'cover' : 'contain',
                backgroundRepeat: 'no-repeat',
                ...style,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export { Image };
