import React, { useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { OSKAppProvider } from '../OSKAppProvider';

export type Auth0ContextProps = {
    domain: string;
    redirectUri: string;
    clientId: string;
};

export type OSKApplicationProps = {
    children?: React.ReactNode;
    name: string;
} & Auth0ContextProps;

const OSKApplication = ({ children, clientId, domain, name, redirectUri }: OSKApplicationProps) => {
    const [rehydrateUri, setRehydrateUri] = useState<string | undefined>(undefined);

    return (
        <Auth0Provider
            onRedirectCallback={(state) => {
                const { redirectUri, nonce } = state;
                if (nonce === sessionStorage.getItem('loginNonce')) {
                    setRehydrateUri(redirectUri);
                }
            }}
            clientId={clientId}
            domain={domain}
            redirectUri={redirectUri}
        >
            <OSKAppProvider rehydrateUri={rehydrateUri} name={name} domain={domain}>
                {children}
            </OSKAppProvider>
        </Auth0Provider>
    );
};

export { OSKApplication };
