import React from 'react';
import styled from 'styled-components';
import { BoxProps, Box } from '../Box';
export type TabItemProps = {
    /** ClassName generated by styled-components */
    className?: string;
    /** Active Tab */
    active: boolean;
    /** Tab label */
    label: string;
    /** Custom styling for tab items */
    tabStyle?: object;
} & Omit<BoxProps, 'ref'>;

const TabItem = styled(({ className, active, label, tabStyle, ...props }: TabItemProps) => {
    return (
        <Box data-testid={`tabItem-${label}`} data-active={active} className={className} style={tabStyle} {...props}>
            {label}
        </Box>
    );
})`
    & {
        font-family: '${(props: any) => props.theme.font}';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        padding: 0 5px;
        color: ${(props: any) => props.theme.colors.primary.fg};
        align-items: center;
        border-bottom: 3px solid transparent;
        cursor: pointer;
    }

    &[data-active='true'] {
        border-bottom: 3px solid ${(props: any) => props.theme.colors.primary.accent};
    }
`;

export { TabItem };
