import { useEffect, useCallback } from 'react';

/**
 * This method will take an HTMLElement and a ref.current element and
 * then walk up the DOM, looking for a match. Effectively, you can use this
 * to see if the evt.target is a sub-tree node of a ref. If it is, this
 * implies that the user has clicked inside whatever ref.current is.
 *
 * Brought in from OSKCore
 */
function clickedInside(el: HTMLElement | null, ref: any): boolean {
    if (el === undefined || el === null) return false;
    if (el === ref) return true;
    return clickedInside(el.parentNode as HTMLElement, ref);
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useClickAway = (ref: any, callback: Function, visible: boolean): void => {
    /**
     * use callback to handle clickAway
     */
    const handleClick = useCallback(
        (evt: Event) => {
            if (ref.current && !clickedInside(evt.target as HTMLElement, ref.current)) {
                callback();
            }
        },
        [ref, visible],
    );

    useEffect(() => {
        // Bind the event listener if visible (rendered)
        if (ref.current && visible) {
            document.addEventListener('mousedown', handleClick);
        }
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClick);
        };
    }, [ref, visible]);
};
