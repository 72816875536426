import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { useRemountOnChange } from '../../hooks';
import { OSKHeaderContext } from '../../templates/OSKView';

export type NavComponentProps = {
    /** Children elements to render in the NavBar */
    children?: React.ReactNode;
};

/**
 * This component will portal children elements into the NavBar
 */
export const NavComponent = ({ children }: NavComponentProps) => {
    const { ref } = useContext(OSKHeaderContext);
    const [visible, _] = useRemountOnChange([ref]);

    return visible ? (
        <React.Fragment>{ref && ref.current && ReactDOM.createPortal(children, ref.current)}</React.Fragment>
    ) : (
        <React.Fragment />
    );
};
