export type Datelike = Date | number | string;

/**
 * A structure representing Date.getMonth() and referencing
 * the English month title for each possible response.
 */
export const MONTHS: Record<number, string> = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
};

/**
 * Abbreviated month list
 */
export const MONTHS_ABBRV: Record<number, string> = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
};
/**
 * Compare two days, regardless of the time component
 */
export function sameDay(left: Date, right: Date) {
    return (
        left.getFullYear() === right.getFullYear() &&
        left.getMonth() === right.getMonth() &&
        left.getDate() === right.getDate()
    );
}

/** Given an arbitrary date, return the last day of the month. */
export function getLastDayOfMonth(date: Date): Date {
    const temp = new Date(date.getTime());
    temp.setMonth(temp.getMonth() + 1);
    temp.setDate(0);
    return temp;
}

export function parseDatelike(datelike?: Datelike): Date | undefined {
    if (!datelike) return undefined;
    if ((datelike as any)['getTime']) {
        return new Date((datelike as Date).getTime());
    } else {
        return new Date(datelike);
    }
}
