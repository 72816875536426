import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'oskcomponents';
import styled from 'styled-components';

export type CoreNavLinkProps = {
    /* The ReactRouter path to navigate to when the link is activated */
    to: string;
    /* Any children elements to render within the typography component */
    children?: React.ReactNode;
    /* An optional method to invoke when the button is pressed */
    onClick?: () => void;
};

const CoreNavLink = ({ children, to, ...props }: CoreNavLinkProps) => (
    <Link to={to} {...props}>
        <Text as="div" {...props}>
            {children}
        </Text>
    </Link>
);

export const NavLink = styled(CoreNavLink)`
    color: white;
    &:hover {
        color: ${(props: any) => props.theme.colors.accent};
    }
`;
