import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { ColorVariants } from '../DefaultThemeProvider';
import { GlobalZIndex } from '../constants';

export type ShimmerProps = {
    /** Override for the background color */
    bg?: string;
    /** ClassName generated by styled-components */
    className?: string;
    /** If true, the inverted attributes will be used instead */
    inverted?: boolean;
    /** Color of moving shimmer inside the bar */
    shimmerColor?: string;
    /** The variant of this button */
    variant: ColorVariants;
    /** Manage visible state of component */
    visible: boolean;
} & Omit<BoxProps, 'ref'>;

const ShimmerBar = styled(({ className, bg, inverted, shimmerColor, visible, ...props }: ShimmerProps) => {
    return (
        <Box grow className={className}>
            <Box data-visible={visible} className="shimmer-bar" grow {...props} />
        </Box>
    );
})`
    @keyframes shimmer {
        0% {
            background-position: -10% top;
            -webkit-background-size: 15% 100%;
        }
        20% {
            -webkit-background-size: 50% 100%;
        }
        70% {
            -webkit-background-size: 10% 100%;
        }
        100% {
            background-position: 120% top;
            -webkit-background-size: 5% 100%;
        }
    }

    .shimmer-bar[data-visible='false'] {
        display: none;
    }

    .shimmer-bar[data-visible='true'] {
        background: ${(props: any) => {
            const bgColor = props.bg ?? props.theme.colors[props.variant].bg;
            const shimmer = props.shimmerColor ?? props.theme.colors[props.variant].invertedBg;
            return (
                bgColor +
                ' -webkit-gradient(linear, 100% 0, 0 0, from(' +
                bgColor +
                '), color-stop(0.5,' +
                shimmer +
                '), to(' +
                bgColor +
                '))'
            );
        }};
        -webkit-animation-name: shimmer;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        background-repeat: no-repeat;
        flex-grow: 0;
    }

    .shimmer-bar {
        position: relative;
        width: 100%;
        height: 4px;
        top: 0;
    }

    & {
        position: absolute;
        width: 100%;
        height: 0px;
        z-index: ${GlobalZIndex.Overlay};
    }
`;

export { ShimmerBar };
