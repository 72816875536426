import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { Heading } from '../Typography';

export type PageScaffoldProps = {
    /** If present, this will be rendered as a Heading element at the top */
    title?: React.ReactElement<any, any>;
    /** An array of ReactElements which may be rendered near the heading as action buttons */
    actions?: Array<React.ReactElement<any, any>>;
    /** Children elements to render inside the content area */
    children?: React.ReactNode;
    /** className prop for style components */
    className?: string;
    /** A array of ReactElements which may be rendered near the heading as navigation buttons */
    navIcon?: Array<React.ReactElement<any, any>>;
} & Omit<Omit<BoxProps, 'ref'>, 'title'>;

/**
 * This component represents a dashboard or page which will be rendered to the screen.
 * It has directives for action elements as well as children elements and a title.
 * Over time, we may add other things like "FloatingActionItems".
 *
 * This is shamelessly inspired by the Flutter component design pattern.
 */
export const PageScaffold = styled(({ actions, children, className, title, navIcon, ...props }: PageScaffoldProps) => {
    return (
        <Box col grow className={className} {...props}>
            {(title || actions) && (
                <Box style={{ paddingBottom: '10px', marginBottom: '20px', borderBottom: '1px solid #515151' }}>
                    <Box style={{ margin: '0px 4px', alignItems: 'center' }}>
                        {navIcon && navIcon.map((icon) => React.cloneElement(icon))}
                    </Box>
                    {title && <Heading variant="medium">{title}</Heading>}
                    <Box style={{ margin: '0px 4px', alignItems: 'center' }}>
                        {actions && actions.map((action) => React.cloneElement(action))}
                    </Box>
                </Box>
            )}
            <Box
                col
                grow
                style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '1000px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                {children}
            </Box>
        </Box>
    );
})`
    & {
        padding: 30px;
    }
`;
