import { MultiPickerProps, MultiSelect } from 'oskcomponents';
import { Role } from 'oskcore';
import React from 'react';

export type RolePickerProps = {
    roles: Array<Role>;
    selected?: Array<string>;
} & Omit<Omit<MultiPickerProps, 'items'>, 'defaultValues'>;

export const RolePicker = ({ roles, selected, ...props }: RolePickerProps) => {
    const defaultValues = roles.filter((role) => (selected ?? []).includes(role.name)).map((role) => role.id);

    return (
        <MultiSelect
            defaultValues={defaultValues}
            items={roles.map((role) => ({
                label: role.name,
                value: role.id,
            }))}
            {...props}
        />
    );
};
