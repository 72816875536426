import React from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import CSS from 'csstype';
import { GlobalZIndex } from '../constants';

type ShadowImageProps = {
    /** The alt-text which will be rendered with the shadow image */
    alt: string;
    /** Any children component to render on top of the shadow image */
    children?: React.ReactNode;
    /** The CSS class which will be applied to the shadow image itself */
    className?: string;
    /** A reference to the image which should be rendered */
    image: string;
    /** Additional styles to include with the shadow image */
    style?: CSS.Properties;
};

const ShadowImage = styled(({ alt, className, children, image, style }: ShadowImageProps) => {
    return (
        <Box
            role="image"
            title={alt}
            style={{
                backgroundImage: `url(${image})`,
                ...style,
            }}
            className={className}
        >
            {children}
        </Box>
    );
})`
    flex-grow: 1;
    opacity: 0.3;
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 120px);
    z-index: ${() => GlobalZIndex.ShadowImage};
    left: 0;
    top: 80px;
`;

export { ShadowImage };
