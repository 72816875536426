import React from 'react';
import { Box, Text } from 'oskcomponents';
import { SearchArea, OSKGeoJson } from 'oskcore';
import { LibraryItem } from '../LibraryItem';

export type LibraryItemListProps = {
    items?: Array<SearchArea>;
    onAreaSelected?: (area: OSKGeoJson) => void;
    onDelete?: (customAreaId: number) => void;
};

export const LibraryItemList = ({ items, onAreaSelected, onDelete }: LibraryItemListProps) => {
    if (!items || items.length === 0) {
        return (
            <Box>
                <Text>You have no items in your library.</Text>
            </Box>
        );
    } else {
        return (
            <React.Fragment>
                {items.map((areaItem) => (
                    <LibraryItem
                        key={areaItem.id}
                        name={areaItem.name}
                        onSelect={() => onAreaSelected && onAreaSelected(OSKGeoJson.fromAPIGeometry(areaItem.area))}
                        onDelete={() => onDelete && onDelete(areaItem.id)}
                    />
                ))}
            </React.Fragment>
        );
    }
};
