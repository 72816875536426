import React from 'react';
import { Box, Text, Table, TableColumn, ListEntry } from 'oskcomponents';
import { ProgramUser, Role } from 'oskcore';
import { IconButton, RolePicker } from '~/atoms';
import { FaTrash } from 'react-icons/fa';

type RoleChangeFn = (user: number, prevRoles: Array<string>, finalRoles: Array<Role>) => void;
type RemoveUserFn = (user: number) => void;

export type ProgramUserTableProps = {
    data?: Array<ProgramUser>;
    onRoleChange?: RoleChangeFn;
    onUserRemoved?: RemoveUserFn;
    readonly?: boolean;
    roles?: Array<Role>;
    loading?: boolean;
};

type RoleCellProps = {
    onRoleChange?: RoleChangeFn;
    data?: any;
    readonly?: boolean;
    roleList?: Array<Role>;
    value?: Array<string>;
    row?: any;
};

type UserActionProps = {
    onUserRemoved?: RemoveUserFn;
    readonly?: boolean;
    row?: any;
};

const UserActions = ({ onUserRemoved, readonly, row: { original } }: UserActionProps) => {
    const { id } = original;
    return (
        !readonly && (
            <IconButton
                onClick={() => {
                    onUserRemoved && onUserRemoved(id);
                }}
                inverted
                variant="contrast"
                icon={FaTrash}
            />
        )
    );
};

const RoleCell = ({ onRoleChange, readonly, roleList, value, row: { original }, ...props }: RoleCellProps) => {
    const handleChange = (selected: ListEntry[]) => {
        const { id } = original;
        const selectedIds = selected.map((item) => item.value as number);

        onRoleChange &&
            onRoleChange(
                id,
                value || [],
                (roleList ?? []).filter((item) => selectedIds.includes(item.id)),
            );
    };

    return (
        <RolePicker
            onChange={handleChange}
            readonly={readonly}
            variant="primary"
            name="role"
            roles={roleList ?? []}
            selected={value ?? []}
        />
    );
};

export const ProgramUserTable = ({
    data,
    onRoleChange,
    onUserRemoved,
    readonly,
    roles,
    loading,
}: ProgramUserTableProps) => {
    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            { Header: 'Email', accessor: 'email' },
            { Header: 'Role', accessor: 'roles', width: 300, Cell: RoleCell },
            { Header: '', accessor: 'options', width: 50, Cell: UserActions },
        ],
        [],
    );

    if (loading) {
        return (
            <Box>
                <Text style={{ fontSize: '1.85rem' }}>Loading...</Text>
            </Box>
        );
    } else if ((data || []).length > 0) {
        return (
            <Table
                roleList={roles}
                onRoleChange={onRoleChange}
                onUserRemoved={onUserRemoved}
                readonly={readonly}
                columns={columns}
                data={data || []}
            />
        );
    } else {
        return (
            <Box>
                <Text style={{ fontSize: '1.5rem' }}>There are no users to display .</Text>
            </Box>
        );
    }
};
