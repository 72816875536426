import { SearchArea, SigmaAPI } from 'oskcore';
import { PaginatedResponse } from '../../paginatedResponse';
import { AppDispatch, RootState } from '../../store';

/* Actions */
export const SET_CUSTOM_AREAS = 'SET_CUSTOM_AREAS';
export function setCustomAreas(areas: PaginatedResponse<SearchArea>) {
    return {
        type: SET_CUSTOM_AREAS,
        payload: {
            areas,
        },
    };
}

export const SET_LIBRARY_LOADING = 'SET_LIBRARY_LOADING';
export function setLibraryLoading(isLoading: boolean) {
    return {
        type: SET_LIBRARY_LOADING,
        payload: {
            isLoading,
        },
    };
}

export type AsyncLibraryRequest = {
    silent?: boolean;
};

export type FetchCustomAreasRequest = {
    offset?: number;
    limit?: number;
} & AsyncLibraryRequest;

export function doFetchCustomAreasAsync({ offset, limit, silent }: FetchCustomAreasRequest) {
    return (dispatch: AppDispatch) => {
        if (!silent) {
            dispatch(setLibraryLoading(true));
        }

        SigmaAPI.listSearchAreas({ limit, offset })
            .then((resp) => {
                if (resp.data && resp.data.results) {
                    resp.data.next;
                    dispatch(
                        setCustomAreas({
                            count: resp.data.count,
                            limit,
                            offset,
                            items: resp.data.results,
                        }),
                    );
                }
            })
            .finally(() => {
                if (!silent) {
                    dispatch(setLibraryLoading(false));
                }
            });
    };
}

export function deleteCustomAreaAsync(customAreaId: number, { silent }: AsyncLibraryRequest) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        if (!silent) {
            dispatch(setLibraryLoading(true));
        }

        SigmaAPI.destroySearchArea({ id: customAreaId }).then(() => {
            const state: RootState = getState();
            dispatch<any>(
                doFetchCustomAreasAsync({
                    limit: state.data.library?.areas?.limit,
                    offset: state.data.library?.areas?.offset,
                    silent,
                }),
            );
        });
    };
}

/* Reducer */
type LibraryType = {
    areas?: PaginatedResponse<SearchArea>;
    isLoading: boolean;
};

const initialState: LibraryType = {
    areas: undefined,
    isLoading: false,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_CUSTOM_AREAS: {
            const { areas } = action.payload;
            return {
                ...state,
                areas,
            };
        }

        case SET_LIBRARY_LOADING: {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoading,
            };
        }

        default:
            return { ...state };
    }
}

/* Selectors */
export const getCustomAreas = (state: RootState) => {
    return state.data.library.areas?.items ?? [];
};

export const getLibraryLoading = (state: RootState) => {
    return state.data.library.isLoading;
};
