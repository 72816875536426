import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { omit } from 'lodash';

type FooterProps = {
    children?: React.ReactNode;
} & BoxProps;

const CoreFooter = ({ children, ...props }: FooterProps) => {
    return (
        <Box {...omit(props, ['ref'])}>
            <footer>{children}</footer>
        </Box>
    );
};

const Footer = styled(CoreFooter)`
    background-color: ${(props: any) => props.theme.colors.black};
    color: ${(props: any) => props.theme.colors.primary.bg};
    padding: ${(props: any) => props.theme.edgeContentPadding};
`;

export type { FooterProps };
export { Footer };
