import React from 'react';
import { Box, BoxProps } from '../Box';
import { GlobalZIndex } from '../constants';
import { omit } from 'lodash';

import CSS from 'csstype';
import './index.css';
import 'animate.css';

type OverlayProps = {
    show: boolean;
    children?: React.ReactNode;
    style?: CSS.Properties;
    animation?: 'none' | 'bounceIn' | 'zoomIn' | 'fadeIn' | 'fadeInDown' | 'slideIn' | 'slideInDown';
} & BoxProps;

const Overlay = ({ animation, children, style, show, ...props }: OverlayProps) => {
    return show ? (
        <Box
            className={animation && `animate__animated animate__${animation} animate__faster`}
            style={{ position: 'relative', zIndex: GlobalZIndex.Overlay }}
        >
            <Box
                style={{
                    position: 'absolute',
                    zIndex: GlobalZIndex.Overlay,
                    ...style,
                }}
                {...omit(props, ['ref'])}
            >
                {children}
            </Box>
        </Box>
    ) : (
        <React.Fragment />
    );
};

export type { OverlayProps };
export { Overlay };
