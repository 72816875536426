class SessionStorage {
    put(key: string, obj: any) {
        sessionStorage.setItem(key, JSON.stringify(obj));
    }

    get(key: string): any {
        // Check session storage first and then local storage.
        // NOTE: This localStorage fallback was initially added to support
        // e2e testing which only has access to localStorage. In general,
        // we should avoid storing things there and instead rely on
        // session storage.
        const candidate = sessionStorage.getItem(key) ?? localStorage.getItem(key);
        if (candidate !== undefined && candidate !== null) {
            return JSON.parse(candidate);
        } else {
            return undefined;
        }
    }
}

export default new SessionStorage();
