/**
 * This class represents a connected footprint bound to a specific fileId.
 */
import React from 'react';
import { connect } from 'react-redux';
import { Footprint, FootprintProps } from '~/atoms/Footprint';
import { RootState } from '~/redux/store';
import { noop, OSKGeoJson } from 'oskcore';
import { getFootprint } from '~/redux/modules/data/map';
import { isItemSelected } from '~/redux/modules/data/cart';

type FileFootprintProps = {
    /** The fileId which this footprint will be rendered for */
    fileId: string;
    /** The GeoJSON feature coordinates contained within this footprint */
    footprint?: OSKGeoJson;
} & Omit<FootprintProps, 'shape'>;

const FileFootprint = ({ footprint, ...props }: FileFootprintProps) => {
    return footprint ? <Footprint shape={footprint} {...props} /> : <React.Fragment />;
};

const mapStateToProps = (state: RootState, ownProps: FileFootprintProps) => {
    const { fileId } = ownProps;
    const { highlightedFootprints, visibleFootprints } = state.data.map;
    return {
        footprint: getFootprint(state, fileId),
        selected: isItemSelected(state, fileId),
        highlighted: highlightedFootprints[fileId],
        userSetVisible: visibleFootprints[fileId],
    };
};

export type { FileFootprintProps };
export { FileFootprint };
export default connect(mapStateToProps, noop)(FileFootprint);
