import { useState, useEffect, useRef } from 'react';

/**
 * This hook will take an image url, load the image, and then
 * set state to true when the image is fully loaded.
 */
export const useWaitForImage = (uri: string) => {
    const [visible, setVisible] = useState(false);
    // Cache the image
    const image = useRef(document.createElement('img'));

    useEffect(() => {
        setVisible(false);
        if (image.current) {
            image.current.onload = () => {
                setVisible(true);
            };
            image.current.onerror = () => {
                setVisible(true);
            };
            image.current.src = uri;
        }
    }, [uri, setVisible, image]);

    return [visible];
};
