import styled from 'styled-components';
import { GlobalZIndex } from '../constants';

type GradientProps = {
    height?: number;
    width?: number;
};

const GradientBar = styled.div<GradientProps>`
    position: absolute;
    left: 0px;
    z-index: ${() => GlobalZIndex.GradientBar};
    width: ${(props: any) => (props.width ? `${props.width}px` : '100%')};
    height: ${(props: any) => props.height ?? 75}px;
    background: linear-gradient(45.61deg, #4954c0 -3.42%, #f46626 50.69%, #ffe6c5 100.44%);
    filter: blur(63px);
    flexbox-grow: 1;
`;

export { GradientBar };
