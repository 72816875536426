import React from 'react';
import { OSKView } from '../../templates/OSKView';
import { Box, Button, Text } from 'oskcomponents';
import OskLogo from '../../assets/images/osk_logo_white_monogram.png';
import { useAuth0 } from '@auth0/auth0-react';
import SpaceImage from '../../assets/images/spaaace.jpg';

type VerifyEmailViewProps = {};
const VerifyEmailView = ({}: VerifyEmailViewProps) => {
    const { loginWithRedirect } = useAuth0();
    const handleLogin = () => {
        localStorage.clear();
        sessionStorage.clear();
        loginWithRedirect({
            audience: 'https://coreapi.orbitalsk.com',
            scope: 'profile',
            max_age: 0,
        });
    };

    return (
        <OSKView noheader grow>
            <Box
                grow
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    backgroundImage: `url('${SpaceImage}')`,
                    backgroundSize: 'cover',
                }}
            >
                <Box
                    style={{
                        backgroundColor: '#111',
                        color: '#ccc',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        textAlign: 'center',
                        height: '550px',
                        paddingTop: '20px',
                        padding: '20px',
                        borderRadius: '14px',
                        width: '450px',
                        filter: 'drop-shadow(0 0 0.55rem black)',
                    }}
                    col
                >
                    <img style={{ padding: '14px' }} width={100} src={OskLogo} />
                    <Text variant="large" p={14}>
                        Welcome to Sigma!
                    </Text>
                    <Text p={14}>
                        To proceed, please verify your email before continuing. Once verified, click here to:
                    </Text>
                    <Button variant="action" style={{ marginTop: '28px' }} onClick={handleLogin}>
                        Login
                    </Button>
                </Box>
            </Box>
        </OSKView>
    );
};

export { VerifyEmailView };
