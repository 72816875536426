import React, { useCallback, useRef, useState } from 'react';
import { Box } from '../Box';
import { Calendar, CalendarProps } from '../Calendar';
import { useClickAway } from '../hooks';

type RangeMode = 'picking' | 'done';

/** Given two dates, this method will return a tuple with the smallest date first. */
function arrangeDates(left?: Date, right?: Date): [Date | undefined, Date | undefined] {
    if (left && right) {
        if (left.getTime() > right.getTime()) {
            return [right, left];
        } else {
            return [left, right];
        }
    } else if (left) {
        return [left, undefined];
    } else if (right) {
        return [right, undefined];
    } else {
        return [undefined, undefined];
    }
}

export type RangeCalendarProps = {
    /** An optional start date to use to seed the selection range.*/
    defaultStartDate?: Date;
    /** An optional end date to use to seed the selection range.*/
    defaultEndDate?: Date;
    /** If true, two calendars will be rendered side-by-side */
    doubleWide?: boolean;
    /** Method to invoke when a specific day is selected */
    onDayClicked?: (day: Date) => void;
    /** Method to invoke when the range is changed */
    onRangeChange?: (start: Date | undefined, end: Date | undefined) => void;
} & Omit<Omit<Omit<Omit<CalendarProps, 'startDate'>, 'endDate'>, 'onDayClick'>, 'onDayEnter'>;

export const RangeCalendar = ({
    origin,
    doubleWide,
    defaultStartDate,
    defaultEndDate,
    onDayClicked,
    onRangeChange,
    ...props
}: RangeCalendarProps) => {
    const firstDate = defaultStartDate ? new Date(defaultStartDate) : new Date();
    const secondDate = defaultEndDate ? new Date(defaultEndDate) : new Date();

    let firstMonth = firstDate;
    let secondMonth = secondDate;
    if (firstDate.getMonth() != secondDate.getMonth()) {
        firstMonth = firstDate;
        secondMonth = secondDate;
    } else if (firstDate.getFullYear() === secondDate.getFullYear()) {
        firstMonth.setMonth(firstMonth.getMonth() - 1);
    }

    const ref = useRef<HTMLDivElement>(null);
    const [rangeStart, setRangeStart] = useState(defaultStartDate ? new Date(defaultStartDate) : undefined);
    const [rangeEnd, setRangeEnd] = useState(defaultEndDate ? new Date(defaultEndDate) : undefined);
    const [rangeMode, setRangeMode] = useState<RangeMode>('done');

    // This method will cancel the current edit operation.
    const cancel = useCallback(() => {
        if (rangeMode === 'picking') {
            setRangeMode('done');
            const [leftDate, rightDate] = arrangeDates(rangeStart, rangeEnd);
            onRangeChange && onRangeChange(leftDate, rightDate);
        }
    }, [rangeMode, rangeStart, onRangeChange, rangeEnd]);

    // This hook will listen for any click event (especially off-screen) and cancel
    // the calendar range picker functionality if needed.
    useClickAway(ref, cancel, rangeMode === 'picking');

    const onClick = useCallback(
        (day: Date) => {
            onDayClicked && onDayClicked(day);
            if (rangeMode === 'done') {
                setRangeMode('picking');
                setRangeStart(day);
                setRangeEnd(day);
            } else if (rangeMode === 'picking') {
                setRangeMode('done');
                setRangeEnd(day);
                const [leftDate, rightDate] = arrangeDates(rangeStart, day);
                onRangeChange && onRangeChange(leftDate, rightDate);
            }
        },
        [onDayClicked, setRangeMode, onRangeChange, rangeStart, rangeMode, setRangeStart, setRangeEnd],
    );

    const onDayEnter = useCallback(
        (day: Date) => {
            if (rangeMode === 'picking') {
                setRangeEnd(day);
            }
        },
        [rangeMode, setRangeEnd],
    );

    const [leftDate, rightDate] = arrangeDates(rangeStart, rangeEnd);

    return (
        <Box ref={ref}>
            {doubleWide ? (
                <>
                    <Calendar
                        origin={firstMonth}
                        onDayClick={onClick}
                        onDayEnter={onDayEnter}
                        startDate={leftDate}
                        endDate={rightDate}
                        style={{ paddingRight: '20px' }}
                        {...props}
                    />
                    <Calendar
                        origin={secondMonth}
                        onDayClick={onClick}
                        onDayEnter={onDayEnter}
                        startDate={leftDate}
                        endDate={rightDate}
                        {...props}
                    />
                </>
            ) : (
                <Calendar
                    origin={firstMonth}
                    onDayClick={onClick}
                    onDayEnter={onDayEnter}
                    startDate={leftDate}
                    endDate={rightDate}
                    {...props}
                />
            )}
        </Box>
    );
};
