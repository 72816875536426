import React from 'react';
import { OSKView } from '../../templates/OSKView';
import { Box, Button, Text } from 'oskcomponents';
import OskLogo from '../../assets/images/osk_logo_white_monogram.png';
import { useAuth0 } from '@auth0/auth0-react';
import SpaceImage from '../../assets/images/spaaace.jpg';
import { useWaitForImage } from '../../hooks';

type ErrorViewProps = {};
const ErrorView = ({}: ErrorViewProps) => {
    const { loginWithRedirect } = useAuth0();
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        loginWithRedirect({
            audience: 'https://coreapi.orbitalsk.com',
            scope: 'profile',
            max_age: 0,
        });
    };

    const [render] = useWaitForImage(SpaceImage);

    return (
        <OSKView noheader grow>
            {render && (
                <Box
                    grow
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        backgroundImage: `url('${SpaceImage}')`,
                        backgroundSize: 'cover',
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: '#111',
                            color: '#ccc',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            height: '350px',
                            paddingTop: '20px',
                            padding: '20px',
                            borderRadius: '14px',
                            width: '450px',
                            filter: 'drop-shadow(0 0 0.55rem black)',
                        }}
                        col
                    >
                        <img style={{ padding: '14px' }} width={100} src={OskLogo} />
                        <Text variant="large" style={{ textAlign: 'center' }} p={14}>
                            Oh no! It seems you don&apos;t have permission to access this page. Please click here to
                            login again:
                        </Text>
                        <Button style={{ marginTop: '14px' }} variant="action" onClick={handleLogout}>
                            Retry Login
                        </Button>
                    </Box>
                </Box>
            )}
        </OSKView>
    );
};

export { ErrorView };
