import React from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps, OSKThemeType } from 'oskcomponents';
import { ActionBar } from '../ActionBar';
import { Breadcrumb, BreadcrumbNodeProps } from '../Breadcrumb';

export type PortalContentViewProps = {
    crumbs?: Array<BreadcrumbNodeProps>;
    children: React.ReactNode;
} & Omit<BoxProps, 'ref'>;

export const PortalContentView = ({ crumbs, children, ...props }: PortalContentViewProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box col grow>
            <ActionBar left={<Breadcrumb nodes={crumbs} />} />
            <Box
                style={{ borderRadius: '30px', margin: '50px' }}
                bg={theme.colors.primary.transBg}
                data-testid="Assets-Section"
                p={24}
                grow
                {...props}
            >
                {children}
            </Box>
        </Box>
    );
};
