import React, { useEffect, useState } from 'react';
import { Shade } from '../Shade';
import { Box } from '../Box';
import { Text } from '../Typography';
import { useTheme } from 'styled-components';
import { OSKThemeType } from '../DefaultThemeProvider';
import { OSKIcon } from '../OSKIcon';
import { CarouselImage, Dot } from '../Carousel';
import { GlobalZIndex } from '../constants';

export type LightboxProps = {
    /** The set of images to display in the lightbox */
    images: CarouselImage[];
    /** The default index of which image is active. Setting this at any time will re-render the component. */
    defaultIndex?: number;
    /** Boolean indicating whether to hide the download button or not */
    hideDownloadButton?: boolean;
    /** The method which is invoked when a new image is loaded */
    onChange?: (image: CarouselImage, index: number) => void;
    /** The method invoked when the lightbox is exited. */
    onExit?: () => void;
};

export const Lightbox = ({ onExit, defaultIndex, hideDownloadButton, onChange, images }: LightboxProps) => {
    const theme = useTheme() as OSKThemeType;
    const [index, setIndex] = useState(defaultIndex ?? 0);
    useEffect(() => {
        onChange && onChange(images[index], index);
    }, [index, images, onChange]);

    useEffect(() => {
        setIndex(defaultIndex ?? 0);
    }, [defaultIndex]);

    const decr = () => {
        if (index - 1 < 0) {
            setIndex(images.length - 1);
        } else {
            setIndex(index - 1);
        }
    };

    const incr = () => {
        if (index + 1 > images.length - 1) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    };

    const filename = images[index].uri;

    return (
        <Shade opacity={0.8}>
            <Box
                style={{
                    width: '80vw',
                    height: '90vh',
                    borderRadius: '30px',
                    backgroundColor: theme.colors.black900,
                    zIndex: GlobalZIndex.ShadeShadow,
                    justifySelf: 'center',
                    alignSelf: 'center',
                }}
                col
            >
                <Box
                    style={{
                        width: '70vw',
                        height: '70vh',
                        zIndex: GlobalZIndex.ShadowImage,
                        background: `url('${filename}')`,
                        backgroundSize: 'contain',
                        filter: 'blur(80px)',
                        position: 'absolute',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        opacity: 0.6,
                        top: '15%',
                    }}
                />
                <Box
                    p={20}
                    pt={40}
                    style={{
                        height: '48px',
                        alignItems: 'center',
                        borderTopRightRadius: '30px',
                        borderTopLeftRadius: '30px',
                    }}
                >
                    <Box col>
                        <Text color={theme.colors.white} strong>
                            {images[index].fileName}
                        </Text>
                        <Text color={theme.colors.black300}>{images[index].subTitle}</Text>
                    </Box>
                    {!hideDownloadButton && (
                        <a href={filename} target="_new" download>
                            <OSKIcon
                                style={{ cursor: 'pointer', paddingLeft: '20px' }}
                                code="download"
                                width={24}
                                height={24}
                                fill="white"
                            />
                        </a>
                    )}
                    <Box grow />
                    <Text color="white" style={{ fill: theme.colors.white, fontSize: '3rem' }}>
                        <OSKIcon onClick={onExit} code="times" style={{ color: theme.colors.white }} />
                    </Text>
                </Box>
                <Box
                    style={{
                        width: '100%',
                        height: '80%',
                        justifyContent: images.length > 1 ? 'space-between' : 'center',
                        alignItems: 'center',
                    }}
                    grow
                >
                    {images.length > 1 && (
                        <OSKIcon p={20} code="arrow-left-with-border" onClick={decr} width={48} height={48} />
                    )}

                    <img
                        height={`80%`}
                        style={{
                            objectFit: 'contain',
                            maxWidth: 'calc(60vw)',
                            margin: '60px',
                        }}
                        src={images[index].uri}
                    />

                    {images.length > 1 && (
                        <OSKIcon p={20} code="arrow-right-with-border" onClick={incr} width={48} height={48} />
                    )}
                </Box>

                <Box style={{ alignSelf: 'center', paddingBottom: '20px' }}>
                    {images.map((img, idx) => (
                        <Dot onClick={setIndex.bind(this, idx)} key={`${img}_${idx}`} active={idx === index} />
                    ))}
                </Box>
            </Box>
        </Shade>
    );
};
