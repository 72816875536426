import React from 'react';
import 'spinkit/spinkit.min.css';
import CSS from 'csstype';
import './spinner.css';

export enum SpinnerType {
    Circle = 'Circle',
    Box = 'Box',
}

export enum SpinnerSize {
    Tiny = 'Tiny',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

type SpinnerProps = {
    /** Additional styling to the container of the spinner */
    style?: CSS.Properties;
    /** What variety of spinner to render */
    variant: SpinnerType | 'Circle' | 'Box';
    /** How large to render the spinner */
    size: SpinnerSize | 'Tiny' | 'Small' | 'Medium' | 'Large';
    /** Whether to render or not */
    visible?: boolean;
};

const dimensions = {
    [SpinnerSize.Tiny]: '14px',
    [SpinnerSize.Small]: '20px',
    [SpinnerSize.Medium]: '42px',
    [SpinnerSize.Large]: '72px',
};

const Spinner = ({ style, visible, variant, size }: SpinnerProps) => {
    if (!visible) return <React.Fragment />;
    switch (variant) {
        case SpinnerType.Circle: {
            return (
                <div
                    className="sk-circle"
                    style={{ margin: 12, width: dimensions[size], height: dimensions[size], ...style }}
                >
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                    <div className="sk-circle-dot"></div>
                </div>
            );
        }
        default: {
            return (
                <div className="sk-grid" style={{ width: dimensions[size], height: dimensions[size], ...style }}>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                    <div className="sk-grid-cube"></div>
                </div>
            );
        }
    }
};

Spinner.defaultProps = {
    variant: SpinnerType.Circle,
    size: SpinnerSize.Small,
    visible: true,
};

export type { SpinnerProps };
export { Spinner };
