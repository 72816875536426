import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, BoxProps, ColorVariants, OSKThemeType, Text } from 'oskcomponents';

export type IconButtonProps = {
    /** Whether this button can be clicked or not */
    disabled?: boolean;
    /** The icon to display inside the button */
    icon?: React.FunctionComponent;
    /** An additional theme to apply to the button container */
    className?: string;
    /** A textual label to render beneath the button */
    label?: string;
    /** If specified, the value to oerride padding */
    p?: number;
    /** The method to invoke when the button is clicked */
    onToggle?: () => void;
    /** If true, the button will invert its style when hovered */
    hoverable?: boolean;
    /** If true, the button will invert its style */
    selected?: boolean;
    /** The size to use when rendering the SVG icon */
    iconSize?: number;
    /** The variant from which to derive color scheme */
    variant: ColorVariants;
    /** If true, the button defaults will be inverted */
    inverted?: boolean;
    /** If provided, override the non-hovered color state */
    fill?: string;
    /** If provided, override the font color */
    color?: string;
} & Omit<BoxProps, 'ref'>;

/**
 * IconButton is a component which renders an icon in a circular button
 * with optional text beneath. It is styled using the variant system
 * and can optionally support mouse-hover effects.
 *
 * To use, simply pass an Icon component and implement onToggle.
 */
const IconButton = styled(
    ({
        className,
        disabled,
        onToggle,
        p,
        hoverable,
        selected,
        iconSize,
        variant,
        icon,
        inverted,
        label,
        fill,
        color,
        ...props
    }: IconButtonProps) => {
        const theme = useTheme() as OSKThemeType;
        const [hovered, setHovered] = useState(false);

        const handleToggle = () => {
            if (!disabled && onToggle) {
                onToggle();
            }
        };

        if (disabled) {
            variant = 'disabled';
        }

        const themeFg = fill ?? theme.colors[variant].fg;
        const themeInvertedFg = fill ?? theme.colors[variant].invertedFg;
        let fg;
        if (selected) {
            fg = inverted ? themeFg : themeInvertedFg;
        } else if (hoverable && hovered) {
            fg = theme.colors[variant].accent;
        } else {
            fg = inverted ? themeInvertedFg : themeFg;
        }

        return (
            <Box
                onMouseOver={setHovered.bind(this, true)}
                onMouseOut={setHovered.bind(this, false)}
                onClick={handleToggle}
                style={{
                    justifyContent: 'center',
                    paddingRight: theme.chipSpacing,
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
                col
                {...props}
            >
                <Text variant="medium">
                    <Box
                        style={{ padding: `${p ?? 10}px`, fontSize: iconSize ? `${iconSize}rem` : '' }}
                        className={className}
                        // fg={fg}
                    >
                        {icon && React.createElement(icon, { fill: fg } as any)}
                    </Box>
                </Text>
                {label && (
                    <Text color={color ?? theme.colors.primary.fg} variant="small" style={{ paddingTop: '0px' }}>
                        {label}
                    </Text>
                )}
            </Box>
        );
    },
)`
    cursor: pointer;
`;

export { IconButton };
