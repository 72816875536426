import React from 'react';
import { Box, BoxProps, Text, OSKThemeType } from 'oskcomponents';
import { AbbreviationIcon } from '../AbbreviationIcon';
import { useTheme } from 'styled-components';

export type UserProgramCardType = {
    /** Whether this card is the active program or not. */
    active?: boolean;
    /** The name of the company this card belongs to. */
    companyName?: string | null;
    /** The colloquial name of the company this card belongs to. */
    name: string;
} & Omit<BoxProps, 'ref'>;

/**
 * A card used to represent a program. Inteded for use with the program switcher logic.
 */
export const UserProgramCard = ({ name, companyName, active, ...props }: UserProgramCardType) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            style={{
                flexGrow: 1,
                alignItems: 'center',
                cursor: 'pointer',
                background: active ? theme.colors.accent : '',
            }}
            p={12}
            {...props}
        >
            {companyName && (
                <Box pr={10}>
                    <AbbreviationIcon label={companyName} />
                </Box>
            )}
            <Text>{name}</Text>
        </Box>
    );
};
