import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/// This view has one purpose. If it is rendered, it will redirect to a specific map
/// view with the location that was most recently saved in SessionStorage or, alternatively,
/// the default osk location.
///
/// It will take this default position and replace it on the navigation stack,
/// effectively acting as a temporary route updater.
export const RedirectToMonitorView = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/monitor`, { replace: true });
    }, [navigate]);
    return <React.Fragment />;
};
