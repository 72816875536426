import React from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps } from 'oskcomponents';
import CSS from 'csstype';
import { SigmaTheme } from '~/main';
import { GlobalZIndex } from '~/constants';

type MapSectionProps = {
    children?: React.ReactNode;
    style?: CSS.Properties;
    bg?: string;
} & Omit<BoxProps, 'ref'>;

/**
 * MapSection is a transparent box of content which
 * is intended to be overlayed on top of the map..
 **/
const MapSection = React.forwardRef<HTMLDivElement, MapSectionProps>(
    ({ bg, children, style, ...props }: MapSectionProps, ref) => {
        const theme = useTheme() as SigmaTheme;

        return (
            <Box style={{ position: 'relative', zIndex: GlobalZIndex.MapSection, paddingTop: '20px', ...style }}>
                <Box p={0} style={{ position: 'relative' }} col>
                    <Box ref={ref} bg={bg ?? theme.colors.gray1a} w={theme.map.panel.width} col {...props}>
                        {children}
                    </Box>
                </Box>
            </Box>
        );
    },
);

export { MapSection };
