import { OSKThemeType, ShimmerBar } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { RootState } from '~/redux/store';

type AppShimmerBarProps = {
    /** Manage visible state of component */
    visible: boolean;
};

const AppShimmerBar = ({ visible }: AppShimmerBarProps) => {
    const theme = useTheme() as OSKThemeType;
    return <ShimmerBar visible={visible} variant="primary" shimmerColor={theme.colors.red} p={2} />;
};

const mapStateToProps = (state: RootState) => {
    const appBusyCount = state.data.app.appBusyCount;
    return {
        visible: appBusyCount > 0,
    };
};

export default connect(mapStateToProps, noop)(AppShimmerBar);
