import { Box, BoxProps, OSKThemeType, Text } from 'oskcomponents';
import React from 'react';
import styled, { useTheme } from 'styled-components';

export type ChoiceCardProps = {
    /** The class used by styled-components */
    className?: string;
    /** An optional label to display inside the choice card */
    label?: string;
    /** The method to invoke when a choice is clicked */
    onClick?: () => void;
    /** Additinoal children elements to render inside the choice card */
    children?: React.Component;
    /** Whether this card is selected or not. This property drives the style. */
    selected?: boolean;
} & Omit<BoxProps, 'ref'>;

export const ChoiceCard = styled(
    ({ className, children, onClick, selected = false, label, ...props }: ChoiceCardProps) => {
        const theme = useTheme() as OSKThemeType;

        return (
            <Box data-selected={selected} className={className} {...props}>
                <Box
                    onClick={onClick}
                    style={{
                        backgroundColor: theme.colors.primary.bg,
                        borderRadius: '6px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px',
                    }}
                >
                    {children}
                    <Text variant="large">{label}</Text>
                </Box>
            </Box>
        );
    },
)`
    & {
        cursor: pointer;
        padding: 4px;
        border-radius: 6px;
        height: 50px;
    }

    &[data-selected='true'] {
        background: rgb(66, 73, 179);
        background: linear-gradient(
            90deg,
            rgba(66, 73, 179, 1) 13%,
            rgba(249, 102, 33, 1) 61%,
            rgba(255, 230, 197, 1) 100%
        );
    }

    &[data-selected='false'] {
        background: #a0a0a0;
        color: #666666;
    }
`;
