import React, { useEffect, useState } from 'react';
import { Box, Text, Table, OSKIcon } from 'oskcomponents';
import { AdminAPI, Program } from 'oskcore';
import { NoPermission } from '~/atoms';
import { useNavigate } from 'react-router-dom';

export type ProgramTableProps = {
    onRowClick?: (program: Record<string, any>) => void;
};

export const ProgramTable = ({ onRowClick }: ProgramTableProps) => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState(true);
    const [rows, setRows] = useState<Program[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        AdminAPI.internalListPrograms()
            .then((resp) => {
                setAllowed(true);
                setRows(resp.data.results ?? []);
            })
            .catch((err) => {
                const { response } = err;
                if (response && response.status === 403) {
                    setAllowed(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'edit',
                Cell: ({ row }: any) => {
                    const { id } = row.values;

                    return (
                        <Box
                            p={4}
                            w={25}
                            onClick={() => {
                                navigate(`/program/${id}`);
                            }}
                        >
                            <OSKIcon code="edit" style={{ cursor: 'pointer' }} />
                        </Box>
                    );
                },
            },
            { Header: 'Id', accessor: 'id', maxWidth: 100, minWidth: 100 },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Company Name', accessor: 'company_name' },
        ],
        [],
    );

    if (loading) {
        return (
            <Box>
                <Text style={{ fontSize: '1.85rem' }}>Loading...</Text>
            </Box>
        );
    } else if (rows.length > 0) {
        return <Table onRowClick={onRowClick} columns={columns} data={rows} />;
    } else {
        return (
            <Box style={{ justifyContent: 'center', paddingTop: '50px' }}>
                {allowed && <Text style={{ fontSize: '1.5rem' }}>There are no programs in the system.</Text>}
                {!allowed && <NoPermission />}
            </Box>
        );
    }
};
