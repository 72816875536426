/* Actions */

/**
 * If busy count is > 0, the app will show a top-level animated bar which
 * indicates the app is busy. Once the count is reduced to zero, the bar will go away.
 * This is a useful mechanism for communicating that something is happening
 * in the background.
 */
export const INCREMENT_BUSY_COUNT = 'INCREMENT_BUSY_COUNT';
export function incrementBusyCount() {
    return {
        type: INCREMENT_BUSY_COUNT,
        payload: {},
    };
}

export const DECREMENT_BUSY_COUNT = 'DECREMENT_BUSY_COUNT';
export function decrementBusyCount() {
    return {
        type: DECREMENT_BUSY_COUNT,
        payload: {},
    };
}

export const CLEAR_BUSY_COUNT = 'CLEAR_BUSY_COUNT';
export function clearCount() {
    return {
        type: CLEAR_BUSY_COUNT,
        payload: {},
    };
}

/* Reducer */
type AppStateType = {
    appBusyCount: number;
};

const initialState: AppStateType = {
    appBusyCount: 0,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case INCREMENT_BUSY_COUNT: {
            const { appBusyCount } = state;
            return {
                ...state,
                appBusyCount: appBusyCount + 1,
            };
        }
        case DECREMENT_BUSY_COUNT: {
            const { appBusyCount } = state;
            return {
                ...state,
                appBusyCount: appBusyCount - 1,
            };
        }
        case CLEAR_BUSY_COUNT: {
            return {
                ...state,
                appBusyCount: 0,
            };
        }

        default:
            return { ...state };
    }
}
