import React from 'react';
import { useTheme } from 'styled-components';
import { OSKView, NavComponent } from 'oskcore';
import { PortalTemplate, ViewItem } from '~/templates/PortalTemplate';
import { ProgramListDashboard } from './ProgramListDashboard';
import { OSKThemeType } from 'oskcomponents';
import { useParams } from 'react-router-dom';
import { ProgramCreateDashboard } from './ProgramCreateDashboard';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import AppShimmerBar from '~/organisms/AppShimmerBar';

const adminPortalOptions: Array<ViewItem> = [
    {
        title: 'Programs',
        subItems: [
            {
                title: 'Edit Programs',
                component: <ProgramListDashboard />,
            },
            {
                title: 'Create Program',
                component: <ProgramCreateDashboard />,
            },
        ],
    },
];

const AdminPortal = () => {
    const { view } = useParams() as any;
    const theme = useTheme() as OSKThemeType;

    return (
        <OSKView headerStyle={{ boxShadow: theme.boxShadow }} nogradient nopadding>
            <NavComponent>
                <SigmaNavItems />
            </NavComponent>
            <AppShimmerBar />
            <PortalTemplate basePath="/admin" defaultViewItem={view} viewItems={adminPortalOptions} />
        </OSKView>
    );
};

export { AdminPortal };
