import React, { useEffect, useRef } from 'react';
import { BasicDialog } from '~/molecules/BasicDialog';
import { Box, Text, TextInput } from 'oskcomponents';

export type AOIFormAttributes = {
    AreaName?: string;
    [key: string]: any;
};

export type AOISaveDialogProps = {
    errorMessage?: string;
    isLoading?: boolean;
    visible: boolean;
    onCancel: () => void;
    onSubmit: (data: AOIFormAttributes) => void;
};

const AOISaveDialog = ({ errorMessage, isLoading, onCancel, onSubmit, visible, ...props }: AOISaveDialogProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef?.current?.focus();
    }, [inputRef, visible]);

    return (
        <BasicDialog
            cancel
            isLoading={isLoading}
            onSubmit={onSubmit}
            onCancel={onCancel}
            visible={visible}
            title="Save AOI Region"
        >
            <Box grow col>
                <Text color="black">AOI Region Name</Text>
                <TextInput ref={inputRef} name="AreaName" />
                <Box style={{ height: '1rem', justifyContent: 'flex-start', paddingTop: '5px' }} grow>
                    {errorMessage && <Text color={'red'}>{errorMessage}</Text>}
                </Box>
            </Box>
        </BasicDialog>
    );
};

export { AOISaveDialog };
