import React from 'react';
import { Box } from 'oskcomponents';
import { BaseAdminDashboard } from '../BaseAdminDashboard';
import { ProgramTable } from '~/organisms/tables/ProgramTable';

export const ProgramListDashboard = () => {
    return (
        <BaseAdminDashboard title={<span>All Programs</span>}>
            <Box grow style={{ marginBottom: '40px', overflowY: 'scroll', display: 'block' }}>
                <ProgramTable />
            </Box>
        </BaseAdminDashboard>
    );
};
