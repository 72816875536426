import { useCallback } from 'react';

// This would essentially be OSKPosition type
// export type MapSession = {
//
// };

export type SessionStorageKeys = 'map';

/**
 * This hooks is used to access and set data in session storage
 *
 *
 */
export const useSessionStorage = () => {
    const setSessionStorage = useCallback((key: SessionStorageKeys, value: any) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, []);

    const getSessionStorage = useCallback(function <Type>(key: SessionStorageKeys): Type | null {
        const item = sessionStorage.getItem(key);
        if (item) {
            return JSON.parse(item) as Type;
        }
        return null;
    }, []);

    return [getSessionStorage, setSessionStorage] as const;
};
