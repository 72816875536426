import { Box, OSKThemeType, Text } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { BasicDialog } from '~/molecules/BasicDialog';

export type RemoveUserDialogProps = {
    visible: boolean;
    email: string;
    onCancel?: () => void;
    onSubmit?: () => void;
};

export const RemoveUserDialog = ({ email, onCancel, onSubmit, visible }: RemoveUserDialogProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <BasicDialog cancel onCancel={onCancel} onSubmit={onSubmit} visible={visible} title="Remove User">
            <Box style={{ textAlign: 'center' }} col>
                <Text color={theme.colors.black900}>Are you sure you wish to remove this user from the program?</Text>
                <Text color={theme.colors.black900} variant="large">
                    {email}
                </Text>
            </Box>
        </BasicDialog>
    );
};
