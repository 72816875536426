import React, { useCallback, useEffect, useState } from 'react';
import { Box, OSKThemeType } from 'oskcomponents';
import { SidebarNavGroup, SidebarNavItemType } from '~/atoms';
import { useNavigate } from 'react-router-dom';
import { toSnakeCase } from 'oskcore';
import { useTheme } from 'styled-components';

export type TemplateHistoryContextType = {
    /** Method used to redirected pages within PortalTemplate views */
    push: (path: string) => void;
};

export const TemplateHistoryContext = React.createContext<TemplateHistoryContextType>({
    push: () => {},
});

export type ViewItem = {
    /** The menu item title for this view */
    title: string;
    /** The component which is rendered when this menu item is selected */
    component?: JSX.Element;
    /** Create a hierarchy of menu items by specifying children menu options */
    subItems?: Array<ViewItem>;
} & SidebarNavItemType;

export type PortalTemplateProps = {
    /** The items to render in the navigation panel */
    viewItems: Array<ViewItem>;

    /** The title of the default selected ViewItem */
    defaultViewItem?: string;

    /** The base path to append all routes with */
    basePath?: string;
};

/**
 * Takes a list of ViewItems and recursively iterates it
 * until finding an element with the same title as the
 * targetTitle attribute, or returns null if none can be found.
 */
function getViewItemByTitle(items: ViewItem[], targetTitle: string): ViewItem | null {
    for (const item of items) {
        if (toSnakeCase(item.title) === targetTitle) {
            return item;
        } else if (item.subItems) {
            const candidate = getViewItemByTitle(item.subItems, targetTitle);
            if (candidate !== null) {
                return candidate;
            }
        }
    }

    return null;
}

function exctractTarget(path: string) {
    if (path === null || path === undefined) {
        console.error('Error: something happened whille changing views in PortalTemplate. \n Path: ', path);
        return '';
    }

    // Check for " / " in path
    const target = path.indexOf('/') > -1 ? path.split('/').splice(-1)[0] : '';
    return target;
}

/**
 * PortalTemplate is a page template you can use to populate a list of
 * view options on the left and a content renderer on the right. A common
 * and intended use-case is like a settings page. Each setting can be
 * its own option and, when clicked, will render a specific content
 * page dedicated to that option. This template handles
 * everything for you. Simply provide it the viewItems and it'll
 * create the common page experience.
 */
export const PortalTemplate = ({ basePath, viewItems, defaultViewItem }: PortalTemplateProps) => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState<ViewItem>();
    const [activeView, setActiveView] = useState<JSX.Element>();
    const theme = useTheme() as OSKThemeType;

    const handleItemClick = useCallback(
        (item: ViewItem | SidebarNavItemType, nav: boolean) => {
            if (basePath && nav) {
                navigate(`${basePath}/${toSnakeCase(item.title)}`);
            }

            setActiveItem(item as ViewItem);
            setActiveView(item.component);
        },
        [setActiveItem, basePath, navigate],
    );

    const updateHistory = (path: string) => {
        const target = exctractTarget(path);
        const view = getViewItemByTitle(viewItems, target);

        handleItemClick(view as ViewItem, true);
    };

    useEffect(() => {
        const defaultSelection = getViewItemByTitle(viewItems, toSnakeCase(defaultViewItem ?? ''));
        if (defaultSelection) {
            handleItemClick(defaultSelection, false);
        } else {
            handleItemClick(viewItems[0], true);
        }
    }, [handleItemClick, defaultViewItem, viewItems]);

    return (
        <TemplateHistoryContext.Provider value={{ push: updateHistory }}>
            <Box data-testid="portal-template" row grow>
                <Box col>
                    {viewItems.map((viewItem, idx) => (
                        <SidebarNavGroup
                            activeItem={activeItem}
                            key={`${idx}_${viewItem.title}`}
                            onClick={(item) => handleItemClick.bind(this)(item, true)}
                            item={viewItem}
                        />
                    ))}
                </Box>
                <Box col grow>
                    <Box grow>{React.isValidElement(activeView) && activeView}</Box>
                </Box>
            </Box>
        </TemplateHistoryContext.Provider>
    );
};
