import { RootState } from '../../store';

/* Actions */
export enum NavItemEntry {
    Downloads = 'Downloads',
    Cart = 'Cart',
    AppList = 'AppList',
    UserProfile = 'UserProfile',
    None = 'None',
}

export const SHOW_NAV_ITEM = 'SHOW_NAV_ITEM';
export function showNavItem(item: NavItemEntry) {
    return {
        type: SHOW_NAV_ITEM,
        payload: {
            item,
        },
    };
}

/* Reducer */
type DownloadStateType = {
    showDownloads: boolean;
    showCart: boolean;
    showAppList: boolean;
    showUserProfile: boolean;
};

const initialState: DownloadStateType = {
    showDownloads: false,
    showCart: false,
    showAppList: false,
    showUserProfile: false,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SHOW_NAV_ITEM: {
            const { item } = action.payload;
            return {
                ...state,
                showDownloads: false,
                showCart: false,
                showAppList: false,
                showUserProfile: false,
                [`show${item}`]: true,
            };
        }

        default:
            return { ...state };
    }
}

export function isNavOpened(state: RootState) {
    return (
        state.data.nav.showAppList ||
        state.data.nav.showCart ||
        state.data.nav.showDownloads ||
        state.data.nav.showUserProfile
    );
}
