import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
export type DividerProps = {
    /** Override for the background color */
    bg?: string;
    /** ClassName generated by styled-components */
    className?: string;
    /** The variant of Divider */
    variant?: string;
} & Omit<BoxProps, 'ref'>;

const Divider = styled(({ className, bg, ...props }: DividerProps) => {
    return <Box className={className} {...props} />;
})`
    & {
        background-color: ${(props: any) => props.bg ?? props.theme.colors[props.variant].border};
        height: 1px;
        flex-grow: 1;
        max-height: 1px;
        min-height: 1px;
    }
`;

Divider.defaultProps = {
    variant: 'primary',
};

export { Divider };
