import { Box, BoxProps, Text } from 'oskcomponents';
import React from 'react';
import styled from 'styled-components';
import { Image } from '~/atoms/Image';
import { CheckIndicator } from '~/atoms/CheckIndicator';
import CSS from 'csstype';

type FileItemProps = Omit<BoxProps, 'ref'> & {
    fileId?: string;
    /** If true, the item will be forced into hover state */
    highlighted?: boolean;
    /** Thumbnail image to display, if applicable */
    img?: string | any;
    /** If true, this entry will be styled as though it were selected */
    selected?: boolean;
    /** File title, if applicable */
    title?: string;
    /** The date at which this file was recorded */
    dateRecorded?: string;
    /** Children elements to render at the bottom of the content area */
    children?: React.ReactNode;
    /** Additional styles to override the content with */
    style?: CSS.Properties;
};

const BaseFileItem = ({ children, dateRecorded, fileId, selected, img, title, ...props }: FileItemProps) => {
    return (
        <Box {...props}>
            <Box p={15} col>
                <Box>
                    <Text color="white" variant={'small'} strong>
                        {title}
                    </Text>
                    <Box grow />
                    <CheckIndicator checked={selected ?? false} />
                </Box>

                {img && <Image w={200} h={75} cover src={img} />}
                {children}
            </Box>
        </Box>
    );
};

const FileItem = styled(({ highlighted, ...props }: FileItemProps) => {
    return <BaseFileItem {...props} />;
})`
    &:hover {
        cursor: pointer;
        background-color: ${(props: any) => 'rgb(0,0,0)'};
    }
`;

export type { FileItemProps };
export { FileItem };
