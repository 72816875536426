import React from 'react';
import {
    Button,
    Box,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
    Spinner,
    SpinnerSize,
    SpinnerType,
} from 'oskcomponents';

export type BasicDialogProps = {
    /** The children elements to render inside the modal body */
    children?: React.ReactNode;
    /** If true, the modal will show a spinner instead of content */
    isLoading?: boolean;
    /** The method to invoke when Cancel is pressed */
    onCancel?: () => void;
    /** The method to invoke when Ok is pressed */
    onSubmit?: (data: Record<string, any>) => void;
    /** If true, the cancel button will be visible */
    cancel?: boolean;
    /** The title to render in the modal dialog */
    title: string;
    /** If true, the modal will be opened */
    visible: boolean;
} & ModalProps;

const BasicDialog = ({
    cancel,
    children,
    isLoading,
    onCancel,
    onSubmit,
    title,
    visible,
    ...props
}: BasicDialogProps) => {
    return (
        <Modal visible={visible} {...props}>
            <Form onSubmit={onSubmit}>
                <ModalHeader variant="primary">{title}</ModalHeader>
                <ModalBody>{children}</ModalBody>
                <ModalFooter>
                    {!isLoading && cancel && <Button variant="primary" onClick={onCancel} label="Cancel" />}
                    {!isLoading && <Button submit variant="action" label="Ok" />}
                    {isLoading && (
                        <Box>
                            <Spinner variant={SpinnerType.Box} size={SpinnerSize.Medium} />
                        </Box>
                    )}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export { BasicDialog };
