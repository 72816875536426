import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { omit } from 'lodash';
import { GlobalZIndex } from '../constants';

type TooltipProps = {
    position?: 'top' | 'bottom' | 'left' | 'right';
    children: React.ReactElement | string;
    label?: JSX.Element | string;
    disabled?: boolean;
} & BoxProps;

const CoreTooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
    ({ className, position, children, label, disabled = false, ...props }: TooltipProps, ref) => {
        const childRef = useRef<any>(null);
        const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
        const [targetSize, setTargetSize] = useState({
            w: 0,
            h: 0,
            x: 0,
            y: 0,
        });
        const [visible, setVisible] = useState<boolean>(false);
        const mouseOverTarget = useCallback(() => {
            setIsMouseOver(true);

            if (label != undefined && label != '' && !disabled) {
                setVisible(true);
            }
        }, [label, disabled]);
        const mouseLeaveTarget = () => {
            setVisible(false);
            setIsMouseOver(false);
        };
        const calculateOffsets = () => {
            if (childRef.current) {
                setTargetSize({
                    w: childRef.current.offsetWidth,
                    h: childRef.current.offsetHeight,
                    x: childRef.current.offsetLeft,
                    y: childRef.current.offsetTop,
                });
            }
        };

        useEffect(() => {
            if (!label) {
                setVisible(false);
            } else if (isMouseOver) {
                setVisible(true);
            }
        }, [label]);

        useEffect(() => {
            window.addEventListener('resize', calculateOffsets);
            return () => {
                window.removeEventListener('resize', calculateOffsets);
            };
        }, []);

        useEffect(() => {
            calculateOffsets();

            if (childRef.current) {
                childRef.current.addEventListener('mouseenter', mouseOverTarget);
                childRef.current.addEventListener('mouseleave', mouseLeaveTarget);

                return () => {
                    childRef.current?.removeEventListener('mouseenter', mouseOverTarget);
                    childRef.current?.removeEventListener('mouseleave', mouseLeaveTarget);
                };
            }
        }, [childRef, mouseOverTarget]);

        return (
            <React.Fragment>
                <Box style={{ width: 'fit-content', height: 'fit-content' }} ref={childRef}>
                    {children}
                </Box>
                {label && (
                    <Box
                        ref={ref}
                        {...omit(props, ['ref'])}
                        className={className}
                        style={{
                            left: `${targetSize.x}px`,
                            top: `${targetSize.y}px`,
                            transform: (() => {
                                switch (position) {
                                    case 'top':
                                        return `translate(calc(-50% + ${targetSize.w / 2}px), calc(-100% - 10px))`;
                                    case 'bottom':
                                        return `translate(calc(-50% + ${targetSize.w / 2}px), ${targetSize.h + 10}px)`;
                                    case 'left':
                                        return `translate(calc(-100% - 14px), calc(-50% + ${targetSize.h / 2}px)`;
                                    case 'right':
                                        return `translate(${targetSize.w + 14}px, calc(-50% + ${targetSize.h / 2}px))`;
                                }
                            })(),
                        }}
                    >
                        <div
                            className={`tooltip-container ${
                                visible && !disabled ? 'tooltip-visible' : 'tooltip-hidden'
                            }`}
                        >
                            <div className="tooltip-box">{label}</div>
                            <div className="tooltip-arrow" />
                        </div>
                    </Box>
                )}
            </React.Fragment>
        );
    },
);

const Tooltip = styled(CoreTooltip)`
    display: block;
    position: absolute;
    width: 250px;
    opacity: 1;
    pointer-events: none;
    z-index: ${() => GlobalZIndex.Tooltip};

    .tooltip-container {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.25s ease-in-out;
    }

    .tooltip-hidden {
        opacity: 0;
    }
    .tooltip-visible {
        opacity: 1;
    }

    .tooltip-box {
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        padding: 14px;
        border-radius: 9px;
        color: white;
        background-color: black;

        min-width: 25px;
        width: fit-content;
        height: fit-content;
    }

    .tooltip-arrow {
        position: absolute;
        float: left;
        width: 20px;
        height: 20px;
        ${(props: any) => {
            switch (props.position) {
                case 'top':
                    return 'bottom: -10px;';
                case 'bottom':
                    return 'top: -10px;';
                case 'left':
                    return 'right: -10px;';
                case 'right':
                    return 'left: -10px;';
            }
        }}

        background-color: black;
        transform: rotate(45deg);
        margin-left: auto;
    }
`;

Tooltip.defaultProps = {
    position: 'top',
};

export { Tooltip };
