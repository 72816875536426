/**
 * This method performs basic float rounding and is
 * improved upon built in methods because you can select
 * the number of digits to have after the rounding operation
 * completes.
 */
export const fround = (num: number, digits: number) => {
    return Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits);
};

/**
 * Take an array of string values and convert them to integers.
 *
 * @param array An array of numeric string values
 * @returns An array of integer values
 */
export const mapToInt = (array: Array<string>) => {
    return array.map((strValue) => parseInt(strValue, 10));
};

/**
 * Take an array of string values and convert them to floats.
 *
 * @param array An array of numeric string values
 * @returns An array of float values
 */
export const mapToFloat = (array: Array<string>) => {
    return array.map((strValue) => parseFloat(strValue));
};
