import React from 'react';
import { useTheme } from 'styled-components';
import { Box, OSKThemeType } from 'oskcomponents';

export type NavItemProps = {
    /** The children elements to render inside the NavItem */
    children: React.ReactNode;
};

export const NavItem = ({ children }: NavItemProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                paddingLeft: `${theme.navItemPadding}px`,
                textAlign: 'center',
                alignItems: 'center',
                verticalAlign: 'center',
                paddingRight: `${theme.navItemPadding}px`,
            }}
        >
            {children}
        </Box>
    );
};
