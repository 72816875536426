import React, { useCallback, useEffect, useRef } from 'react';
import { Box, BoxProps } from '../Box';

/** The dimension in which to measure. */
export type MeasuredLayoutDimension = 'horizontal' | 'vertical';
export type MeasuredLayoutProps = {
    /** Children react elements to render inside a constrained viewport. */
    children: React.ReactNode;
    /** The dimension in which to constrain the layout. */
    dimension?: MeasuredLayoutDimension;
} & Omit<BoxProps, 'ref'>;

/**
 * This method will take a reference to a container and then use binary search
 * to measure the available space remaining until a scrollbar appears.
 *
 * You can use this method in either horizontal or vertical dimmensions. It is intended
 * primarily to constrain the height of <Map /> elements, but useful for any kind of
 * viewport-constrained layout requirement.
 *
 * @param target A ref to the container we wish to measure.
 * @param dimension Whether to measure horizontally or vertically.
 * @returns The remaining space available until a scrollbar appears.
 */
function calculateDimension(
    /** The target element to calculate dimensions against */
    target: HTMLElement,
    /** The dimension in which to calculate. */
    dimension: MeasuredLayoutDimension = 'vertical',
    /** Internal private attribute to propagate the created child element. */
    child: HTMLDivElement | undefined = undefined,
    /** Internal private attribute to track the minimum boundary of the binary search. */
    min: number | undefined = undefined,
    /** Internal private attribute to track the maximum boundary of the binary search. */
    max: number | undefined = undefined,
    /** Internal private attribute to prevent infinite loops. */
    shortCircuit = 256,
): number {
    if (shortCircuit < 0) {
        let result;

        if (child) {
            if (dimension === 'horizontal') {
                result = parseInt(child.style.width);
            } else {
                result = parseInt(child.style.height);
            }

            child.remove();
        } else {
            result = 0;
        }

        console.error(`Error: Unable to calculate ${dimension} dimension in MeasuredLayout component.`);
        return result;
    }

    // Create a child component if it doesn't not already exist. This will be our agent
    // whose job is to flexibly resize itself until scrollbars disappear.
    if (!child) {
        child = document.createElement('div');
        child.style.position = 'absolute';
        if (dimension === 'horizontal') {
            child.style.width = `${window.innerWidth}px`;
            child.style.height = `10px`;
        } else {
            child.style.height = `${window.innerHeight}px`;
            child.style.width = `10px`;
        }
        target.appendChild(child);
    } else if (min && max) {
        // If the child exists and min & max are set, then we can adjust its height
        // using a form of binary search.
        const sizePx = `${min + (max - min) / 2}px`;
        if (dimension === 'horizontal') {
            child.style.width = sizePx;
        } else {
            child.style.height = sizePx;
        }
    }

    if (dimension === 'horizontal') {
        min = min ?? window.innerWidth / 2;
        max = max ?? window.innerWidth;
    } else {
        min = min ?? window.innerHeight / 2;
        max = max ?? window.innerHeight;
    }

    // Check for the presence of scrollbars
    let node: HTMLElement | null = target;
    let scrollDeployed = false;

    while (node) {
        const scrollTarget = dimension === 'horizontal' ? node.scrollWidth : node.scrollHeight;
        const size = dimension === 'horizontal' ? node.clientWidth : node.clientHeight;

        if (scrollTarget > size && size > 0) {
            scrollDeployed = true;
            break;
        }

        node = node.parentElement;
    }

    if (scrollDeployed === false) {
        min += Math.abs(max - min) / 2;
        max += Math.abs(max - min) / 2;
    } else {
        max -= (max - min) / 2;
    }

    // This will be true if the min and max are far away from eachother
    const far = Math.abs(max - min) > 5;

    // If the min and max are close together but the scrollbar is still deployed,
    // it's time to do some fine-tuning.
    if (!far && scrollDeployed) {
        min -= 2;
        max -= 1;
        return calculateDimension(target, dimension, child, min, max, shortCircuit - 1);
    } else if (far) {
        // If the min and max are just generally far away, we can use regular
        // binary search.
        return calculateDimension(target, dimension, child, min, max, shortCircuit - 1);
    } else {
        // If the min and max are close together and the scrollbar is nowhere to be seen,
        // then we have arrived at the result.
        let result;
        if (dimension === 'horizontal') {
            result = parseInt(child.style.width);
        } else {
            result = parseInt(child.style.height);
        }

        child.remove();
        return result;
    }
}

/**
 * This component will constrain its children elements to the exact pixels remaining until a scrollbar appears.
 * Useful if you need to take up the exact remaining amount of space, in pixels. A positive byproduct of this is
 * that styles which use things like height: 100% will be constrained as you expect. Example:
 *
 * ```
 * <MeasuredLayout dimension='vertical'>
 *  <EditableMap />
 * </MeasuredLayout>
 * ```
 */
export const MeasuredLayout = ({ children, dimension = 'vertical', ...props }: MeasuredLayoutProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const childRef = useRef<HTMLDivElement | null>(null);

    const calculateLayout = useCallback(() => {
        // Measure
        if (ref.current && childRef.current) {
            const originalVisibility = childRef.current.style.visibility;
            const originalDisplay = childRef.current.style.display;

            childRef.current.style.visibility = 'hidden';
            childRef.current.style.display = 'none';

            if (dimension === 'horizontal') {
                childRef.current.style.width = '0px';
                const size = calculateDimension(ref.current, dimension);
                childRef.current.style.width = `${size}px`;
            } else {
                childRef.current.style.height = '0px';
                const size = calculateDimension(ref.current, dimension);
                childRef.current.style.height = `${size}px`;
            }

            childRef.current.style.visibility = originalVisibility;
            childRef.current.style.display = originalDisplay;
        }
    }, [ref, childRef]);

    useEffect(() => {
        calculateLayout();
        window.addEventListener('resize', calculateLayout);
        return () => {
            window.removeEventListener('resize', calculateLayout);
        };
    }, [ref]);

    return (
        <Box ref={ref} {...props}>
            <Box ref={childRef} style={props.style}>
                {children}
            </Box>
        </Box>
    );
};
