import React, { useState } from 'react';
import { Carousel, CarouselProps } from '../Carousel';
import { Lightbox } from '../Lightbox/Lightbox';

export type LightboxCarouselProps = {
    /** Boolean indicating whether to hide the download button */
    hideDownloadButton?: boolean;
} & Omit<Omit<Omit<CarouselProps, 'defaultIndex'>, 'onExpanded'>, 'onChange'>;

export const LightboxCarousel = ({ hideDownloadButton, ...props }: LightboxCarouselProps) => {
    const [showLightbox, setShowLightbox] = useState(false);
    const [index, setIndex] = useState(0);

    return (
        <React.Fragment>
            <Carousel
                onChange={(image, idx) => {
                    setIndex(idx);
                }}
                onExpanded={setShowLightbox.bind(this, true)}
                defaultIndex={index}
                {...props}
            />
            {showLightbox && (
                <Lightbox
                    onChange={(image, idx) => {
                        setIndex(idx);
                    }}
                    hideDownloadButton={hideDownloadButton}
                    onExit={setShowLightbox.bind(this, false)}
                    defaultIndex={index}
                    images={props.images}
                />
            )}
        </React.Fragment>
    );
};
