import React from 'react';
import { AppContext } from '../../templates/OSKAppProvider';
import { NavLink } from '../NavLink';

export type LogoutButtonProps = {
    children: React.ReactNode;
};

export const LogoutButton = ({ children }: LogoutButtonProps) => {
    return (
        <AppContext.Consumer>
            {({ doLogout }) => (
                <NavLink to="/" onClick={doLogout}>
                    {children}
                </NavLink>
            )}
        </AppContext.Consumer>
    );
};
