/**
 * DownloadStatus represents various statuses that a download operation
 * may be experiencing.
 */
export enum DownloadStatus {
    Idle,
    Pending,
    Ready,
    Finished,
}
