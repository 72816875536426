import { Box, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';

type ActionBarProps = {
    /** React component(s) that will go in the left side of the bar*/
    left?: React.ReactNode | Array<React.ReactNode>;
    /** React component(s) that will go in the right side of the bar */
    right?: React.ReactNode | Array<React.ReactNode>;
};
const ActionBar = ({ left, right, ...props }: ActionBarProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            row
            h={43}
            style={{
                position: 'relative',
                zIndex: GlobalZIndex.ActionBar,
                backgroundColor: theme.colors.primary.halfTransBg,
                flexShrink: 0,
            }}
            {...props}
        >
            <Box grow style={{ alignItems: 'center', paddingLeft: '14px' }}>
                {left}
            </Box>
            <Box grow style={{ justifyContent: 'flex-end', alignItems: 'center', paddingRight: '48px' }}>
                {right}
            </Box>
        </Box>
    );
};

export { ActionBar };
export type { ActionBarProps };
