import { useState, useEffect } from 'react';

/**
 * This hook will take a boolean variable you wish to monitor
 * and when it changes, the component will unount and remount.
 */
export const useRemountOnChange = (toMonitor: Array<any>) => {
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        setVisible(false);
    }, toMonitor);

    useEffect(() => {
        if (visible == false) {
            setVisible(true);
        }
    }, [visible]);

    return [visible, setVisible];
};
