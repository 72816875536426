import React from 'react';
import { useTheme } from 'styled-components';
import { Text, OSKThemeType } from 'oskcomponents';
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';

export type CheckIndicatorType = {
    /** If true, the component will be green. Otherwise, it will be gray. */
    checked: boolean;
};

/**
 * CheckIndicator is a component which renders a checkmark.
 * If checked is true, the mark will be green. Otherwise it
 * will be gray.
 */
export const CheckIndicator = ({ checked }: CheckIndicatorType) => {
    const theme = useTheme() as OSKThemeType;
    if (checked) {
        return (
            <Text color={'lime'}>
                <FaCheckCircle />
            </Text>
        );
    } else {
        return (
            <Text color={theme.colors.disabled.fg}>
                <FaRegCheckCircle />
            </Text>
        );
    }
};
