import React, { useEffect } from 'react';
import { Box, BoxProps } from '../../Box';
import styled from 'styled-components';

export type YearPickerProps = {
    /** The date of the currently selected element. This will default to today. */
    selected?: Date;
    /** Method to invoke when a year is selected */
    onYearSelected: (year: number) => void;
    /** The minimum year you can pick in the year picker carousel */
    minYear?: number;
    /** The maximum year you can pick in the year picker carousel */
    maxYear?: number;
    /** The class that is used by styled-components */
    className?: string;
} & Omit<BoxProps, 'ref'>;
export const YearPicker = styled(
    ({ className, minYear, maxYear, onYearSelected, selected = new Date(), ...props }: YearPickerProps) => {
        const today = new Date();
        const rangeStart = minYear ?? 1960;
        const rangeEnd = maxYear ?? today.getFullYear() + 10;

        const range: Array<number> = [];
        for (let year = rangeStart; year < rangeEnd; year++) {
            range.push(year);
        }

        useEffect(() => {
            document.getElementById(`year-${selected.getFullYear()}`)?.scrollIntoView({ block: 'center' });
        });

        return (
            <Box className={className} {...props}>
                {range.map((year) => {
                    return (
                        <Box
                            id={`year-${year}`}
                            onClick={() => {
                                onYearSelected && onYearSelected(year);
                            }}
                            data-current={selected.getFullYear() === year}
                            key={`year-${year}`}
                        >
                            {year}
                        </Box>
                    );
                })}
            </Box>
        );
    },
)`
    & {
        width: 100%;
        display: block;
        overflow: scroll;
        height: 235px;
        margin: 20px;
        font-size: 1.5rem;
        line-height: 3rem;
    }

    & div {
        display: block;
        text-align: center;
    }

    & div:hover,
    & div[data-current='true'] {
        color: ${(props: any) => props.theme.colors.accent};
    }
`;
