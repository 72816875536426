import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Text } from 'oskcomponents';
import { FaDrawPolygon, FaTrash } from 'react-icons/fa';

export type LibraryItemProps = {
    className?: string;
    name: string;

    onSelect: () => void;
    onDelete: () => void;
};

export const LibraryItem = styled(({ className, name, onSelect, onDelete }: LibraryItemProps) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Box
            grow
            style={{
                padding: '2px 0px',
                paddingLeft: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.12rem',
            }}
        >
            <FaDrawPolygon style={{ paddingRight: '5px' }} />
            <Text className={className} onClick={onSelect}>
                {name}
            </Text>
            <Box grow />
            <Button
                variant="primary"
                disabled={isLoading}
                onClick={() => {
                    setIsLoading(true);
                    onDelete && onDelete();
                }}
                style={{ height: '32px', padding: '4px' }}
                flat
            >
                <FaTrash />
            </Button>
        </Box>
    );
})`
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.colors['action'].bg};
    }
`;
