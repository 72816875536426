import React from 'react';
import { FaCaretDown, FaTimesCircle } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';
import { Box, BoxProps } from '../Box';
import { OSKThemeType } from '../DefaultThemeProvider';
import { Text } from '../Typography';

export type ChipProps = {
    /** A functional component which will be rendered as the icon */
    icon?: React.FunctionComponent | null;
    /** The CSS class to render with this item */
    className?: string;
    /** Which variant to render. The selected variant has some coerced styling */
    variant?: 'default' | 'selected';
    /** Text to display inside the chip */
    text?: string;
    /** If specified, a label will be present which describes the subsequent text */
    label?: string;
    /** Background color override */
    bg?: string;
    /** Foreground color override */
    fg?: string;
} & Omit<BoxProps, 'ref'>;

const BaseChip = styled(({ bg, className, fg, icon = FaCaretDown, label, text, ...props }: ChipProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box style={{ paddingRight: theme.chipSpacing, paddingBottom: theme.chipSpacing }} {...props}>
            <Text as="div" style={{ backgroundColor: bg }} className={className} variant="tiny" color={fg}>
                <Box>
                    {label && (
                        <Box style={{ borderRadius: '0.5rem' }} fg={theme.colors.white} bg={theme.colors.gray75} p={8}>
                            {label}
                        </Box>
                    )}

                    <Box p={8}>
                        {text}
                        {icon && <Box style={{ paddingLeft: '10px' }}>{icon && React.createElement(icon)}</Box>}
                    </Box>
                </Box>
            </Text>
        </Box>
    );
})`
    border-radius: 0.75em;
    cursor: pointer;
`;

const Chip = ({ variant, ...props }: ChipProps) => {
    const theme = useTheme() as OSKThemeType;
    if (variant === 'default') {
        return <BaseChip {...props} />;
    } else {
        return <BaseChip {...props} fg={theme.colors.white} bg={theme.colors.gray100} icon={FaTimesCircle} />;
    }
};

export { Chip };
