/**
 * Perform shallowEquals against two entities of any type.
 *
 * If they are object types, it will compare keys and primitive values (not references)
 * If they are arrays, it will iterate over the array and compare each nested object
 * If they are primitives, it will do a basic === comparison on them.
 */
export function shallowEquals(a: any, b: any): Boolean {
    if ((a === null && b === null) || (a === undefined && b === undefined)) {
        return true;
    } else if (
        (a === null && b !== null) ||
        (b === null && a !== null) ||
        (a === undefined && b !== undefined) ||
        (b === undefined && a !== undefined)
    ) {
        return false;
    } else if (typeof a !== typeof b) {
        return false;
    } else if (Array.isArray(a)) {
        if (a.length !== b.length) return false;
        for (let idx = 0; idx < a.length; idx++) {
            if (!shallowEquals(a[idx], b[idx])) {
                return false;
            }
        }
    } else if (typeof a === 'object') {
        for (let key in a) {
            if (!b.hasOwnProperty(key)) return false;
        }
    } else {
        return a === b;
    }
    return true;
}
