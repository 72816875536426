import { OSKGeoJson } from 'oskcore';
import React, { useEffect } from 'react';
import { useMap } from '~/hooks';

export type MapCentroidProps = {
    area?: OSKGeoJson;
    padding?: number;
    animated?: boolean;
};

export const MapCentroid = ({ animated, area, padding }: MapCentroidProps) => {
    const map = useMap();
    useEffect(() => {
        if (area && !area.isEmpty()) {
            map.fitCoordinates([area], padding, animated);
        }
    }, [area, animated, map.editableMap]);

    return <React.Fragment />;
};
