import React, { useCallback, useEffect, useState } from 'react';
import { Box, BoxProps, Button, OSKThemeType } from 'oskcomponents';
import { StepStatus, WizardStepList } from '~/atoms';
import CSS from 'csstype';
import { WizardPage } from './WizardPage';
import { useTheme } from 'styled-components';

function calculateStepStatus(currentStep: number, itemStep: number): StepStatus {
    if (currentStep > itemStep) {
        return 'Done';
    } else if (currentStep === itemStep) {
        return 'Current';
    } else {
        return 'Pending';
    }
}

export type WizardContextType = {
    /**
     * A method to set the validity of the currently active step. If the validity is false,
     * the Continue button will be disabled.
     */
    setValidity: (isValid: boolean) => void;
    /** The currently active step number */
    currentStep: number;
};

export const WizardContext = React.createContext<WizardContextType>({
    setValidity: () => {},
    currentStep: 0,
});

export type WizardStep = {
    label: string;
    view: React.ReactNode;
};

export type WizardTemplateProps = {
    /** The list of steps to populate this wizard with. */
    steps: Array<WizardStep>;
    /** Additional styling for the wizard container. */
    style?: CSS.Properties;
    /** The method to invoke when the final step is completed. */
    onSubmit?: () => void;
} & Omit<BoxProps, 'ref'>;

/**
 The WizardTemplate is a template you can use to generate a
 step-based wizard experience. To use this component, pass in
 the steps prop like so. Any step which has the status of 'Current'
 will have the view prop be automatically rendered:

 ``` 
<WizardTemplate
    steps={[
        {
            label: 'Data Source',
            view: <DataSourceStep />,
        },
        {
            label: 'Time Frame',
            view: <TimeFrameStep />,
        },
        {
            label: 'AOI Region',
            view: <AOIStep />,
        },
        {
            label: 'Tasking Order',
            view: <ReviewStep />,
        },
    ]}
/>
 ```
*/
export const WizardTemplate = ({ steps, style, onSubmit, ...props }: WizardTemplateProps) => {
    const [stepValidity, setStepValidity] = useState<Record<number, boolean>>({});
    // StepRendered is a mechanism which prevents rendering a particular view until the user
    // has landed on the relevant step. This is because things like maps won't render properly
    // if they are hidden when loaded into the DOM.
    const [stepRendered, setStepRendered] = useState<Record<number, boolean>>({});
    const [currentStep, setCurrentStep] = useState(0);

    const theme = useTheme() as OSKThemeType;

    // When a user changes currentStep, make sure to render
    // the relevant page.
    useEffect(() => {
        setStepRendered({
            ...stepRendered,
            [currentStep]: true,
        });
    }, [currentStep, setStepRendered]);

    const setValidity = useCallback(
        (isValid) => {
            setStepValidity({
                ...stepValidity,
                [currentStep]: isValid,
            });
        },
        [setStepValidity, stepValidity, currentStep],
    );

    return (
        <React.Fragment>
            <WizardContext.Provider
                value={{
                    setValidity,
                    currentStep: currentStep,
                }}
            >
                <Box
                    col
                    style={{
                        alignItems: 'center',
                        borderRadius: '30px',
                        width: '100%',
                        backgroundColor: theme.colors.primary.transBg,
                        position: 'relative',
                        height: '100%',
                        overflow: 'hidden',
                        ...style,
                    }}
                    grow
                    {...props}
                >
                    <WizardStepList
                        style={{
                            width: `calc(100% - 60px)`,
                            marginTop: `25px`,
                            justifyContent: 'center',
                            borderRadius: '15px',
                            flexGrow: 0,
                        }}
                        steps={steps.map((step, idx) => ({
                            label: step.label,
                            stepNumber: idx,
                            status: calculateStepStatus(currentStep, idx),
                        }))}
                    />
                    <Box
                        style={{ width: `calc(100% - 60px)`, overflow: 'hidden', zIndex: 1, marginBottom: `25px` }}
                        col
                        grow
                    >
                        {steps.map((step, idx) => (
                            <WizardPage key={step.label} stepNumber={idx}>
                                {stepRendered[idx] && step.view}
                            </WizardPage>
                        ))}
                    </Box>
                    <Box
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(/images/rainier_c2.png)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '185% 50%',
                            backgroundSize: '75%',
                            filter: 'opacity(0.2) grayscale(100%)',
                        }}
                    ></Box>
                </Box>

                <Box>
                    {currentStep > 0 && (
                        <Button
                            type="button"
                            variant="action"
                            style={{ marginTop: '15px', marginRight: '15px' }}
                            onClick={() => {
                                setCurrentStep(Math.max(currentStep - 1, 0));
                            }}
                        >
                            Back
                        </Button>
                    )}
                    <Button
                        type={currentStep === steps.length - 1 ? 'submit' : 'button'}
                        variant="action"
                        style={{ marginTop: '15px' }}
                        disabled={!stepValidity[currentStep]}
                        onClick={() => {
                            if (currentStep === steps.length - 1 && onSubmit) {
                                onSubmit();
                            }

                            // Wait a tick and then update the step. This trick ensures we don't
                            // change the screen while the button is pressed, which causes
                            // race conditions apparently.
                            setTimeout(() => {
                                setCurrentStep(Math.min(currentStep + 1, steps.length - 1));
                            }, 0);
                        }}
                    >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                </Box>
            </WizardContext.Provider>
        </React.Fragment>
    );
};
