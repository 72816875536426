import React from 'react';
import { Box, BoxProps } from '../Box';

type RatioPanelProps = {
    /** Our parent panel adds up the total weight of all children to calculate the "ratio step" */
    weight: number;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
} & Omit<BoxProps, 'ref'>;

type RatioLayoutProps = {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
} & Omit<BoxProps, 'ref'>;

const RatioPanel = ({ className, style, children, weight, ...props }: RatioPanelProps) => {
    return (
        <RatioLayoutContext.Consumer>
            {(ratioStep) =>
                children ? (
                    <Box
                        className={className}
                        col
                        grow
                        style={{
                            width: `${weight ? ratioStep * weight : 100}%`,
                            overflow: 'hidden',
                            position: 'relative',
                            ...style,
                        }}
                        {...props}
                    >
                        {children}
                    </Box>
                ) : (
                    <React.Fragment></React.Fragment>
                )
            }
        </RatioLayoutContext.Consumer>
    );
};

const RatioLayoutContext = React.createContext(0);

/** A wrapper to create a weighted ratio of child `RatioPanel` elements.
 
    Example:
    ```
    <RatioLayout>
        <RatioPanel weight={2}>Panel 1</RatioPanel>
        <RatioPanel weight={1}>Panel 2</RatioPanel>
    </RatioLayout>
    ```
*/
const RatioLayout = ({ children, ...props }: RatioLayoutProps) => {
    const FilteredChildren = React.Children.toArray(children).filter((child) => {
        if ((child as React.ReactElement).type === RatioPanel) {
            return true;
        } else {
            console.error(
                `Unsupported element in RatioLayout: ${(child as React.ReactElement).type}. Expected: RatioPanel.`,
            );
            return false;
        }
    });

    const totalWeight: number = FilteredChildren.reduce<number>(
        (total, child) => total + (child as React.ReactElement).props.weight,
        0,
    );

    // Create context for child elements with the ratio "step"
    // (100% / total ratio weight)
    const ratioStep = 100 / totalWeight;

    return (
        <RatioLayoutContext.Provider value={ratioStep}>
            <Box {...props} grow row>
                {FilteredChildren}
            </Box>
        </RatioLayoutContext.Provider>
    );
};

export { RatioPanel, RatioLayout, RatioLayoutContext };
export type { RatioPanelProps };
