import React from 'react';
import { Box, Text } from 'oskcomponents';
import SpaceImage from '../../assets/images/spaaace.jpg';
import { useWaitForImage } from '../../hooks';

type LoginViewProps = {};
const LoginView = ({}: LoginViewProps) => {
    const [render] = useWaitForImage(SpaceImage);

    return (
        <React.Fragment>
            {render && (
                <Box
                    grow
                    style={{
                        justifyContent: 'center',
                        paddingTop: '40vh',
                        backgroundImage: `url('${SpaceImage}')`,
                        backgroundSize: 'cover',
                    }}
                >
                    <Text variant="large" color="black" style={{ fontSize: '3.2rem' }}>
                        Working...
                    </Text>
                </Box>
            )}
        </React.Fragment>
    );
};

export { LoginView };
