import React, { useEffect } from 'react';
import { MapAPI, useMap } from '~/hooks';

export type MapRefresherProps = {
    onLoad?: (map: MapAPI) => void;
};

export const MapRefresher = ({ onLoad }: MapRefresherProps) => {
    const map = useMap();
    useEffect(() => {
        onLoad && onLoad(map);
    }, [onLoad, map]);

    return <></>;
};
