import React, { useRef } from 'react';
import { Box } from '../Box';
import CSS from 'csstype';
export const PortalContext = React.createContext<any>(null);

type PortalProps = {
    children?: React.ReactNode;
    style?: CSS.Properties;
};

/**
 * This is a container which will render children elements and then a container.
 * Any downstream component which uses React.createPortal and references
 * the context of this component will end up in the container closest
 * to the DOM.
 */
const Portal = ({ children, style }: PortalProps) => {
    const ref = useRef(null);
    return (
        <PortalContext.Provider value={ref}>
            {children}
            <Box ref={ref} style={style} />
        </PortalContext.Provider>
    );
};

export { Portal };
