import React from 'react';
import { Box, BoxProps } from '../Box';
import { Shade } from '../Shade';

export type ModalProps = {
    /** Children components to render inside the modal */
    children?: React.ReactNode;
    /** Override the top position of the modal */
    top?: number;
    /** Whether to show the modal or not */
    visible: boolean;
} & Omit<BoxProps, 'ref'>;

/**
 * The Modal component is composed with a <Shroud /> element to create
 * a space for the modal pop-over and is intended to be composed with
 * <ModalBody /> <ModalFooter /> and <ModalHeader>. Doing so will create
 * a standardized popup which can be used for any blocking user action.
 */
const Modal = ({ children, top, visible, ...props }: ModalProps) => {
    return visible ? (
        <Shade>
            <Box
                style={{
                    marginTop: `${top ?? 150}px`,
                    minWidth: '350px',
                }}
                {...props}
                col
            >
                {children}
            </Box>
        </Shade>
    ) : (
        <React.Fragment />
    );
};

export { Modal };
