import React from 'react';
import { NavComponent, OSKView } from 'oskcore';
import { ShadowImage } from 'oskcomponents';
import { Box, Heading, Text } from 'oskcomponents';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';

const NotFoundView = () => {
    return (
        <OSKView>
            <NavComponent>
                <SigmaNavItems />
            </NavComponent>
            <ShadowImage alt="Orbital Sidekick logo" image={'/images/rainier_11b.png'} />
            <Box style={{ justifyContent: 'center', textAlign: 'center' }} grow>
                <Box style={{ paddingTop: '100px', maxWidth: '768px' }} col>
                    <header>
                        <Heading variant="large" p={40} style={{ justifyContent: 'center' }}>
                            Oh no!
                        </Heading>
                    </header>
                    <section>
                        <Text style={{ justifyContent: 'center' }}>
                            It looks like we&apos;ve run into a problem. The original page you were seeking cannot be
                            found. Please try again.
                        </Text>
                    </section>
                </Box>
            </Box>
        </OSKView>
    );
};

export default NotFoundView;
