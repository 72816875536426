import React from 'react';
import { AppName } from '~/atoms';
import AppList from '~/organisms/AppList';
import UserProfile from '~/organisms/UserProfile';

export const SigmaNavItems = () => {
    return (
        <React.Fragment>
            <AppList />
            <UserProfile />
            <AppName />
        </React.Fragment>
    );
};
