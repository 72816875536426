import React, { useRef } from 'react';
import { Box, Spinner, Text } from 'oskcomponents';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { SearchArea, OSKGeoJson } from 'oskcore';
import { FaFolder } from 'react-icons/fa';
import { LibraryItemList } from '~/atoms';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { deleteCustomAreaAsync, getCustomAreas, getLibraryLoading } from '~/redux/modules/data/library';

export type LibraryPanelProps = {
    isLoading?: boolean;
    items?: Array<SearchArea>;
    setMapRoi?: (aoi: OSKGeoJson) => void;
    deleteItem?: (customAreaId: number) => void;
};

export const LibraryPanel = ({ isLoading, items, setMapRoi, deleteItem }: LibraryPanelProps) => {
    const mapSectionRef = useRef(null);
    useDisableFeatureOnMouseOver(mapSectionRef, 'Zoom', true);

    return (
        <Box
            p={15}
            style={{
                borderRadius: '15px',
                marginTop: '15px',
                overflowY: 'auto',
                overflowX: 'auto',
            }}
            col
        >
            <Box style={{ paddingBottom: '10px' }}>
                <FaFolder style={{ fontSize: '1.4rem', paddingRight: '10px' }} />
                <Text variant="large">Library</Text>
            </Box>
            {isLoading ? (
                <Box p={20}>
                    <Spinner size="Medium" variant="Box" />
                </Box>
            ) : (
                <LibraryItemList onDelete={deleteItem} onAreaSelected={setMapRoi} items={items} />
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: getLibraryLoading(state),
        items: getCustomAreas(state),
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        deleteItem: (customAreaId: number) => {
            dispatch<any>(deleteCustomAreaAsync(customAreaId, { silent: true }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryPanel);
